import React from "react";

import {
    Col,
    Row,
    Container,
  } from "reactstrap";
  
import './LandDownload.scss' ;



//icons 
import phones from '../../../../assets/img/icons/LandDownload/phones.svg' ;
import line from '../../../../assets/img/icons/LandDownload/line.svg' ;
import playstore from '../../../../assets/img/icons/LandDownload/playstore.svg' ;
import appstore from '../../../../assets/img/icons/LandDownload/appstore.svg' ;


  const LandDownload = (props) => {

    const download_app_store = () => {
        window.open('https://apps.apple.com/app/punchword/id1540343095', '_blank').focus();
    }


    const download_play_store = () => {
        window.open('https://play.google.com/store/apps/details?id=com.frmoh.punchword', '_blank').focus();
    }
  
    return (
          <>
          <div className="w-100 p-0 m-0 d-flex justify-content-center align-items-center" style={{minHeight : "calc( 100vh - 100px )"}}>
        
            <Container className="container_land_download">
                <Row className="row_land_download ">
                    <div className="background_row_land_download">
                        <img src={
                            line
                        }
                        alt=''/>
                    </div>

                    <Col xs="12" md="6" className="p-0 m-0 d-flex justify-content-center align-items-center">
                        <img className="image_shown_phones" style={{width : "100%" , maxWidth : "710px"}}  src={
                            phones
                        }
                        alt=''/>
                    </Col>
                    <Col xs="12" md="6"  className="p-0 m-0 d-flex justify-content-center align-items-center">
                        <div className="w-75">
                            <div className="punchytoken_text1">
                                Download our beta version on  the store.
                            </div>
                            <div className="punchytoken_text2">
                                One of the first feature available is called « the punchword »
                            </div>
                            <div  className="download_image_store_div" style={{marginTop : "20px" , marginBottom : "20px"}}>
                                <img src={
                                    appstore
                                    }
                                    alt=''
                                    style={{
                                        maxWidth: "180px" , 
                                        cursor : "pointer", 
                                        marginRight : "15px"
                                    }}
                                    className="d-inline download_image_store"
                                    onClick={download_app_store}
                                />
                                <img src={
                                    playstore
                                }
                                alt=''
                                style={{
                                    maxWidth: "180px" , 
                                    cursor : "pointer" 
                                }}
                                className="d-inline download_image_store"
                                onClick={download_play_store}
                                />
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
            </div>
          </>
    );
  };
  
  export default LandDownload;
  