/*!

=========================================================
* Punchword - v0.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import Acount from "views/examples/Acount.js";
import Latest from "views/examples/Latest";

import Following from "views/examples/Following";
import EditProfile from "views/examples/EditProfile";

import LandingPage from "views/pages/LandingPage/LandingPage";
import Login from "views/pages/Auth/Login/Login";
import ForgetPassword from "views/pages/Auth/ForgetPassword/ForgetPassword";
import ResetPassword from "views/pages/Auth/ResetPassword/ResetPassword";
import Register from "views/pages/Auth/Register/Register";
import AccountHome from "views/pages/Account/Home/AccountHome";
import MessangerSpace from "views/pages/Account/Messages/MessangerSpace";
import NftMarket from "views/pages/Account/NftMarket/NftMarket";
import AccountProfile from "views/pages/Account/Profile/AccountProfile";
import ProfileSettings from "views/pages/Account/Profile/components/ProfileSettings/ProfileSettings";
import ProfileFavorites from "views/pages/Account/Profile/components/ProfileFavorites/ProfileFavorites";
import ProfileOffers from "views/pages/Account/Profile/components/ProfileOffers/ProfileOffers";

var routes = [
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Index,
  //   layout: "/admin",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin",
  // },
  // {
  //   path: "/login",
  //   name: "Login",
  //   icon: "ni ni-key-25 text-info",
  //   component: Login,
  //   layout: "/auth",
  // },
  // {
  //   path: "/forget_password",
  //   name: "ForgetPassword",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: ForgetPassword,
  //   layout: "/auth",
  // },
  // {
  //   path: "/reset_password",
  //   name: "ResetPassword",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: ResetPassword,
  //   layout: "/auth",
  // },
  {
    path: "/",
    name: "Home",
    icon: "ni ni-key-25 text-info",
    component: LandingPage,
    layout: "/home",
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth",
  // },


  // {
  //   path: "/acount",
  //   name: "Acount",
  //   icon: "ni ni-key-25 text-info",
  //   component: Acount,
  //   layout: "/user",
  // },
  // {
  //   path: "/edit-profile",
  //   name: "Edit Profile",
  //   icon: "ni ni-key-25 text-info",
  //   component: EditProfile,
  //   layout: "/user",
  // },
  // {
  //   path: "/latest",
  //   name: "Latest",
  //   icon: "ni ni-key-25 text-info",
  //   component: Latest,
  //   layout: "/user",
  // },
  // {
  //   path: "/home",
  //   name: "AccountHome",
  //   icon: "ni ni-key-25 text-info",
  //   component: AccountHome,
  //   layout: "/user",
  // },
  // {
  //   path: "/market",
  //   name: "Market",
  //   icon: "ni ni-key-25 text-info",
  //   component: AccountHome,
  //   layout: "/user",
  // },
  // {
  //   path: "/messages",
  //   name: "Messages",
  //   icon: "ni ni-key-25 text-info",
  //   component: MessangerSpace,
  //   layout: "/user",
  // },
  // {
  //   path: "/following",
  //   name: "Following",
  //   icon: "ni ni-key-25 text-info",
  //   component: Following,
  //   layout: "/user",
  // },
  // {
  //   path: "/search",
  //   name: "Nft Market",
  //   icon: "ni ni-key-25 text-info",
  //   component: NftMarket,
  //   layout: "/user",
  // },
  // {
  //   path: "/profile",
  //   name: "Profile",
  //   icon: "ni ni-key-25 text-info",
  //   component: AccountProfile,
  //   layout: "/user",
  // },
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   icon: "ni ni-key-25 text-info",
  //   component: ProfileSettings,
  //   layout: "/user",
  // },
  // {
  //   path: "/favorites",
  //   name: "Favorites",
  //   icon: "ni ni-key-25 text-info",
  //   component: ProfileFavorites,
  //   layout: "/user",
  // },
  // {
  //   path: "/offers",
  //   name: "Offers",
  //   icon: "ni ni-key-25 text-info",
  //   component: ProfileOffers,
  //   layout: "/user",
  // }
  
];
export default routes;
