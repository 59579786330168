/*!

=========================================================
* Punchword - v0.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { auth } from "app/firebase";
import { db } from "app/firebase";
import { providerOut } from "app/firebase";
import { addDoc, collection } from "firebase/firestore";
import { Link, useHistory } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";

import user_img from '../../assets/img/theme/user_punchy.png' ;
import abdou_img from '../../assets/img/theme/abd.jpg' ;
import './NotificationsBar.scss' ;

import back_img from '../../assets/img/theme/test_back.jpg' ;
import PerfectScrollbar from 'react-perfect-scrollbar' ;
import 'react-perfect-scrollbar/dist/css/styles.css';
import $ from 'jquery';


 
import close from '../../assets/img/icons/market/close.svg' ; 

const NotificationsBar = (session) => {
  
  console.log(session);

  
  var current_user = localStorage.getItem("user") ;

  if(current_user && current_user !== "") {
    current_user = JSON.parse(current_user) ;
  }else {
    current_user = null ;
  }


  const  history = useHistory();
  
  

  const closeNotificationsBar = () => {
    $(".all_notifications_bar").css({"display" : "none"})   ;  
    }


  const renderStories = () => {
        var list = [] ; 

        for (let index = 0; index < 20; index++) {
        list.push(
            <Col xs="12" className="mb-3">
                <div className="w-100 d-flex align-items-center justify-content-between py-0">
                    <div className="d-flex justify-content-start align-items-center">

                        { index%3 !== 1 &&
                            <div className="card_profile_image d-flex justify-content-center align-items-center">
                                <div className="card_profile_image_div">
                                    <img
                                    alt="..."
                                    className="rounded-circle"
                                    src={
                                        current_user && current_user.imageURL !== "" ? current_user.imageURL : user_img
                                    }
                                    />

                                </div>
                                { index%3 === 1 &&
                                    <div className="badge">
                                    LIVE
                                </div>
                                }
                                    
                            </div>
                        }


                        { index%3 === 1 &&
                            <div className="image_notification_like_all d-flex justify-content-center align-items-center">
                                <div className="image_notification_like_div1">
                                    <img
                                    alt="..."
                                    className="rounded-circle"
                                    src={
                                        current_user && current_user.imageURL !== "" ? current_user.imageURL : user_img
                                    }
                                    />

                                </div>

                                <div className="image_notification_like_div2">
                                    <img
                                    alt="..."
                                    className="rounded-circle"
                                    src={
                                        abdou_img
                                    }
                                    />

                                </div>
                                    
                            </div>
                        }
                        
                    <div>
                        
                    <div className="text-center mt-2 ml-2">
                        <div  style={{fontSize : "10px" , color : "#2f3232" , marginBottom: "0"}}>
                            <span className="mr-2" style={{fontWeight : "600"}}>
                                {current_user ? current_user.username : "UserName"} 
                            </span>
                            start following you
                        </div>
                        <div className="h5 font-weight-300" style={{fontSize : "9px" , color : "rgb(142 142 142)" , marginBottom: "0" , textAlign : "left"}}>
                            Yesterday
                        </div>
                    </div>
                        


                    </div>
                </div>

                { index%3 === 0 &&
                <div  className="px-3 py-2  font-weight-600 follow_btn d-flex justify-content-center align-items-center">
                        FOLLOW
                </div>
                }

                { index%3 === 2 &&
                    <div  className="px-3 py-2  font-weight-600 unfollow_btn d-flex justify-content-center align-items-center ">
                            UNFOLLOW  
                    </div>
                }

                { index%3 === 1 &&
                
                    <div className="image_notification_like d-flex justify-content-center align-items-center">
                        <img
                            alt="..."
                            
                            src={
                                back_img
                            }
                            />
                </div>
                }
            

            </div>

        </Col>
        ) ;
        
        }
        

        return list ;
    }

  


  return (
    <>
      <Card className="shadow border-0 mt-4 mx-auto all_notifications_bar w-100" style={{maxWidth : "580px" , background : "#fffffd" ,borderRadius : "10px"}}>
        
            <CardBody >
                <div className="w-100  mb-3 d-flex justify-content-between align-items-center">
                    <div  style={{fontWeight: "600" , color : "#2f3232", fontSize : "16px" , textAlign : "left"}}>
                        Notifications
                    </div>
                    <div style={{fontSize : "25px" , color : "#2f3232" , cursor : "pointer"}}>
                        <img onClick={closeNotificationsBar}
                            alt="..."
                            className="filter_icon"
                            style={{width : "20px" , height : "auto" , }}
                            src={
                                close
                            }
                        />
                    </div>

                </div>

                <PerfectScrollbar>
                    <Row className="ml-0 w-100 pb-5">
                            {
                            renderStories()
                            }

                    </Row>
                </PerfectScrollbar>
            </CardBody>
            
          </Card>
      
    </>
  );
};

export default NotificationsBar;
