
import {  useHistory } from "react-router-dom";
import './StorieItem.scss' ;


import 'react-perfect-scrollbar/dist/css/styles.css';
import $ from 'jquery';

import Stories from '@reactrondev/react-stories'

import {  useState } from "react";
import MarketItem from "../../NftMarket/MarketItem/MarketItem";



const StorieItem = (props) => {
  
  console.log(props);

  const [value, setValue] = useState(props.active.value);

  props.active.subscribe(val => {
    if(value !== val) {
      setValue(val)
    }
  }) ;
  
  
  var current_user = localStorage.getItem("user") ;

  if(current_user && current_user !== "") {
    current_user = JSON.parse(current_user) ;
  }else {
    current_user = null ;
  }

  const post_model_image = {
        uImgURL:  "https://firebasestorage.googleapis.com/v0/b/react-auth-be92d.appspot.com/o/images%2FgZrVu1cKPlRWc1u6VHp9u6tJWZ23_abd.jpg?alt=media&token=fa410ce2-ac8c-405c-9d73-62da2602f414"  ,
        
        likeCount:  0 ,
        commentCount:  0 ,
        shareCount:  0 ,
        
        fullName: "Abdennour Badeche" ,
        pImgURL: "https://firebasestorage.googleapis.com/v0/b/react-auth-be92d.appspot.com/o/images%2F296178014_153823693897401_5120699166089147296_n.jpg?alt=media&token=b6a4fbe1-7b28-41d3-a7b3-e8d3fd9e5b5f" ,
        
    
  }


  const  history = useHistory();
  
  

  const closeStoriesBar = () => {
    $(".all_stories_bar").css({"display" : "none"})   ;  
    }

    const  header =  { 
        heading: current_user ? current_user.username : "Username", 
        subheading: 'Posted 32m ago', 
        profileImage: current_user ? current_user.imageURL : "" 
    } 

var stories_background = "https://picsum.photos/1080/1920" ;

      const stories = [
        { 
            url: 'https://picsum.photos/1080/1920', 
            seeMore: <MarketItem post={post_model_image} />, 
            header: header
        }, 
        { 
            url: 'https://fsa.zobj.net/crop.php?r=dyJ08vhfPsUL3UkJ2aFaLo1LK5lhjA_5o6qEmWe7CW6P4bdk5Se2tYqxc8M3tcgYCwKp0IAyf0cmw9yCmOviFYb5JteeZgYClrug_bvSGgQxKGEUjH9H3s7PS9fQa3rpK3DN3nx-qA-mf6XN', 
            
            header: header
        }, 
        { 
            url: 'https://media.idownloadblog.com/wp-content/uploads/2016/04/iPhone-wallpaper-abstract-portrait-stars-macinmac.jpg', 
            
            header: header
        }, 
        { 
            url: 'https://storage.googleapis.com/coverr-main/mp4/Footboys.mp4', 
            type: 'video', 
            duration: 1000 ,
            header: header
        }, 
        { 
            url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4', 
            type: 'video',
            header: header ,
            seeMore: <MarketItem post={post_model_image} />, 
        }, 
        { 
            url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4', 
            type: 'video' ,
            header: header
        }
    ]


    const clickStorie = () => {
      props.active.next(props.index) ;
      setValue(props.index) ;
    }

  return (
    <>
    <div className={(value === props.index ? "active_story " : "")+" storie_background"}  
          style={{marginRight : "30px" , background: "url("+stories_background+")"}}
          onClick={clickStorie}
          >

      <div className="one_storie_list_home">
          <div className="card_profile_image w-100 d-flex justify-content-center align-items-center">
              <div className="card_profile_image_div">
                  <img
                  alt="..."
                  className="rounded-circle"
                  src={
                    current_user ? current_user.imageURL : ""
                  }
                  />

              </div>
                  
          </div>
          <div className="text-center mt-2">
                      <div  className="storie_username">
                      {current_user ? current_user.username : "Username"}
                      </div>
          </div>
      
      </div>
    <div className="stories_content">
                <Stories
                    width={450}
                    height={800}
                    stories={stories}
                    
                />
    </div>
    </div>
    
    </>
  );
};

export default StorieItem;
