import {  useHistory } from "react-router-dom";
// reactstrap components
import {
  Row,
  Card,
  CardBody,
} from "reactstrap";
import './StoriesBar.scss' ;

import 'react-perfect-scrollbar/dist/css/styles.css';
import $ from 'jquery';
import HorizontalScroll from "react-scroll-horizontal";


import StorieItem from "./StorieItem";
import { BehaviorSubject } from "rxjs";

import close from '../../../../../assets/img/icons/market/close.svg' ; 
import MarketItem from "../../NftMarket/MarketItem/MarketItem";


const StoriesBar = (session) => {
  
  console.log(session);

  
  var current_user = localStorage.getItem("user") ;

  if(current_user && current_user !== "") {
    current_user = JSON.parse(current_user) ;
  }else {
    current_user = null ;
  }

  const post_model_image = {
        uImgURL:  "https://firebasestorage.googleapis.com/v0/b/react-auth-be92d.appspot.com/o/images%2FgZrVu1cKPlRWc1u6VHp9u6tJWZ23_abd.jpg?alt=media&token=fa410ce2-ac8c-405c-9d73-62da2602f414"  ,
        
        likeCount:  0 ,
        commentCount:  0 ,
        shareCount:  0 ,
        
        fullName: "Abdennour Badeche" ,
        pImgURL: "https://firebasestorage.googleapis.com/v0/b/react-auth-be92d.appspot.com/o/images%2F296178014_153823693897401_5120699166089147296_n.jpg?alt=media&token=b6a4fbe1-7b28-41d3-a7b3-e8d3fd9e5b5f" ,
        
    
  }


  const  history = useHistory();
  
  

  const closeStoriesBar = () => {
    $(".all_stories_bar").css({"display" : "none"})   ;  
    }


      const stories = [
        { 
            url: 'https://picsum.photos/1080/1920', 
            seeMore: <MarketItem post={post_model_image} />, 
            header: { 
                heading: 'Mohit Karekar', 
                subheading: 'Posted 5h ago', 
                profileImage: 'https://picsum.photos/1000/1000' 
            } 
        }, 
        { 
            url: 'https://fsa.zobj.net/crop.php?r=dyJ08vhfPsUL3UkJ2aFaLo1LK5lhjA_5o6qEmWe7CW6P4bdk5Se2tYqxc8M3tcgYCwKp0IAyf0cmw9yCmOviFYb5JteeZgYClrug_bvSGgQxKGEUjH9H3s7PS9fQa3rpK3DN3nx-qA-mf6XN', 
            header: { 
                heading: 'Mohit Karekar', 
                subheading: 'Posted 32m ago', 
                profileImage: 'https://picsum.photos/1080/1920' 
            } 
        }, 
        { 
            url: 'https://media.idownloadblog.com/wp-content/uploads/2016/04/iPhone-wallpaper-abstract-portrait-stars-macinmac.jpg', 
            header: { 
                heading: 'reactrondev/@reactrondev/react-stories', 
                subheading: 'Posted 32m ago', 
                profileImage: 'https://avatars0.githubusercontent.com/u/24852829?s=400&v=4' 
            } 
        }, 
        { 
            url: 'https://storage.googleapis.com/coverr-main/mp4/Footboys.mp4', 
            type: 'video', 
            duration: 1000 
        }, 
        { 
            url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4', 
            type: 'video', 
            seeMore: <MarketItem post={post_model_image} />, 
        }, 
        { 
            url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4', 
            type: 'video' 
        }, 
        'https://images.unsplash.com/photo-1534856966153-c86d43d53fe0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=564&q=80'
    ]

    var index_active_story  = new BehaviorSubject(2) ;

    index_active_story.subscribe(val => {
      console.log(val) ;
    }) ;

  const renderStories = () => {
        var list = [] ; 

        for (let index = 0; index < 20; index++) {
        list.push(
            <StorieItem active={index_active_story} index={index}></StorieItem>
        ) ;
        
        }
        

        return list ;
    }

  


  return (
    <>
      <Card className="shadow border-0 m-0 all_stories_bar w-100" style={{background : "rgb(0 0 0 / 59%)" }}>
        
            <CardBody className=" d-flex justify-content-center align-items-center">
                <Row className="w-100" style={{height : "100%"}}>
                    <HorizontalScroll>
                            {
                            renderStories()
                            }
                    </HorizontalScroll>
                </Row>
                  
                <div className="close_div" style={{fontSize : "30px" , color : "white" , cursor : "pointer"}}>
                        <img onClick={closeStoriesBar}
                            alt="..."
                            className="filter_icon"
                            style={{width : "20px" , height : "auto" , }}
                            src={
                                close
                            }
                        />
                    </div>
            </CardBody>
            
          </Card>
      
    </>
  );
};

export default StoriesBar;
