import React from "react";

import {
    Col,
    Row,
    Container,
  } from "reactstrap";
  
import './LandCreatePunchword.scss' ;



//icons 
import line from '../../../../assets/img/icons/LandCreatePunchword/line.svg' ;

  const LandCreatePunchword = (props) => {

    return (
          <>
          <div className="w-100 p-0 m-0 d-flex justify-content-center align-items-center" >
            <Container className="container_land_create_punchword">
                <Row className="row_land_create_punchword ">
                    <Col xs="12" className="p-0 m-0   d-flex justify-content-center align-items-center" style={{zIndex : "3" }}>
                            <div className="punchytoken_text1  text-center"  style={{background : "white"}}>
                            One of our first feature allows you to add quotes and punchline to your post.<br/> Here is how it works :

                            </div>
                    </Col>

                    <Col xs="12" className="p-0 m-0 mt-2   d-flex justify-content-center align-items-center" style={{zIndex : "3" }}>
                            <div className="punchytoken_text2  text-center"  style={{maxWidth : "800px" , background : "white"}}>
                                You can create your punchword in one minute and share it with your network easily.
                            </div>
                    </Col>


                    <Col xs="12" className="p-0 m-0 d-flex justify-content-center align-items-center" style={{zIndex : "2" , position : "relative" , marginTop : "50px"}}>
                        <img className="phones_image_land_create_punchword"
                        src={
                            line
                        }
                        alt=''
                        />
                        <div className="row ml-0 row_content_image_create_punchword"  style={{ width : "90%" }} >
                            <div className="col-12 col-md-6  col-lg-3 mb-3 d-flex justify-content-center align-items-center ">
                                <div className="w-100 land_create_punchword_item" style={{height : "100%"}}>
                                    <div className=" rounded-circle bg-white">
                                        1.
                                    </div>
                                    <div className="punchytoken_text3">
                                        Select your picture
                                    </div>
                                    <div className="punchytoken_text4">
                                        Select a picture in your gallery or take a picture. These photos can be personal pictures, selfies, landscapes… This picture will be the the background of the idea you want to express.
                                    </div>
                                </div>
                            </div>


                            <div className="col-12 col-md-6  col-lg-3 mb-3 d-flex justify-content-center align-items-center ">
                                <div className="w-100 land_create_punchword_item" style={{height : "100%"}}>
                                    <div className=" rounded-circle bg-white">
                                        2.
                                    </div>
                                    <div className="punchytoken_text3"  style={{width : "90%"}}>
                                        Select or write your quote

                                    </div>
                                    <div className="punchytoken_text4">
                                        You can choose a quote or book passage directly in our Library which includes thousands of quotes in different languages, or write you own original “punchword”.
                                    </div>
                                </div>
                            </div>


                            <div className="col-12 col-md-6  col-lg-3 mb-3 d-flex justify-content-center align-items-center ">
                                <div className="w-100 land_create_punchword_item" style={{height : "100%"}}>
                                    <div className=" rounded-circle bg-white">
                                        3.
                                    </div>
                                    <div className="punchytoken_text3"  style={{width : "90%"}}>
                                        Record your voice post
                                    </div>
                                    <div className="punchytoken_text4">
                                        Tap on the speaker button and record your voice message. You can explain your punchword, tell a story, sing …
                                    </div>
                                </div>
                            </div>


                            <div className="col-12 col-md-6  col-lg-3 mb-3 d-flex justify-content-center align-items-center ">
                                <div className="w-100 land_create_punchword_item" style={{height : "100%"}}>
                                    <div className=" rounded-circle bg-white">
                                        4.
                                    </div>
                                    <div className="punchytoken_text3" style={{width : "90%"}}>
                                        Share with your network
                                    </div>
                                    <div className="punchytoken_text4">
                                        You can share your creation directly on Punchword but also on your favorite social networks (Facebook, instragram, Snapchat, Twitter…) by just one click.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            </div>
          </>
    );
  };
  
  export default LandCreatePunchword;
  