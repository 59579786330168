// Import the functions you need from the SDKs you need
import {initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword , signOut , createUserWithEmailAndPassword , signInWithPopup } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBz6-a_Lc9t3nzYeaGp54tcd05kIP23NRI",
  authDomain: "react-auth-be92d.firebaseapp.com",
  projectId: "react-auth-be92d",
  storageBucket: "react-auth-be92d.appspot.com",
  messagingSenderId: "413574549746",
  appId: "1:413574549746:web:dc1dbe08eb9f5e3c71406b"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth() ;
const provider =  signInWithEmailAndPassword ; 
const providerOut =  signOut ; 
const providerCreate =  createUserWithEmailAndPassword ;
const providerGoogle = new GoogleAuthProvider();
const signInWithPopupApp = signInWithPopup ;

const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp) ;

export {firebaseApp , auth , provider , providerOut , providerCreate , providerGoogle , signInWithPopupApp , db , storage} ;