import React, {  useState } from "react";


import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { useHistory } from "react-router-dom";

//video
import insta from '../../../../../../assets/img/icons/Profile/Settings/insta.svg' ;
import facebook from '../../../../../../assets/img/icons/Profile/Settings/facebook.svg' ;
import twitter from '../../../../../../assets/img/icons/Profile/Settings/twitter.svg' ;
import linkedIn from '../../../../../../assets/img/icons/Profile/Settings/linkedIn.svg' ;
import tiktok from '../../../../../../assets/img/icons/Profile/Settings/tiktok.svg' ;
import youtube from '../../../../../../assets/img/icons/Profile/Settings/youtube.svg' ;
  
import './ProfileSettings.scss' ;
import { FormControlLabel, styled, Switch } from "@mui/material";
import BlockListBar from "components/Sidebar/BlockListBar";
import $ from 'jquery';



import hidden_password from '../../../../../../assets/img/icons/Login/hidden_password.svg' ;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#e30613',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const ProfileSettings = (session) => {

    console.log("ProfileSettings session");
    console.log(session);

    var current_user = localStorage.getItem("user") ;

    if(current_user && current_user !== "") {
      current_user = JSON.parse(current_user) ;
    }else {
      current_user = null ;
    } ;

    const [changePassword , setChangePassword] = useState(false) ;

  const [email , setEmail] = useState('') ;
  const [password , setPassword] = useState('') ;
  const [password2 , setPassword2] = useState('') ;

  const [fullname , setFullname] = useState('') ;
  const [username , setUsername] = useState('') ;
  const [gender , setGender] = useState('Male') ;

  const [agree , setAgree] = useState('') ;
  const [valid , setValid] = useState(false) ;

  const [showPassword , setShowPassword] = useState(false) ;

  const set_ShowPassword = () => {
    setShowPassword(!showPassword)
  }



  React.useEffect(() => {
    
    var valid = true ;

    if((!fullname || fullname === "") || (!username || username === "") || (!gender || gender === "") || (!email || email === "" || !String(email).toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ))
      || (!password || password === "") || (!password2 || password2 === "") || (password2 !== password) || !agree
    ) {
      valid = false ;
    }

    setValid(valid) ;

 },[ email , password , password2 , fullname , username , gender , agree ])



  

  const notify = (message) => {
    
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000
      });
  }

  const  history = useHistory();

  // const dispatch = useDispatch() ; 
  

  // const userName = useSelector(selectUserName) ;
  // const userEmail = useSelector(selectUserEmail) ;

  const signUp = () => {
    var user = {
      fullname ,
      username ,
      gender ,
      email ,
      password ,
      password2 ,
      agree
    } ; 
    console.log(user) ;

    var valid = true ;

    if(!fullname || fullname === "") {
      notify("Enter your fullname please !") ;
      valid = false ;
    }
    else if(!username || username === "") {
      notify("Enter your username please !") ;
      valid = false ;
    }

    else if(!gender || gender === "") {
      notify("Enter your gender please !") ;
      valid = false ;
    }
    else if(!email || email === "" || !String(email).toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )) {
      notify("Enter your email please !") ;
      valid = false ;
    }
    else if(!password || password === "") {
      notify("Enter your password please !") ;
      valid = false ;
    }
    else if(!password2 || password2 === "") {
      notify("Confirm your password please !") ;
      valid = false ;
    }
    else if(password2 !== password) {
      notify("Re-confirm your password please !") ;
      valid = false ;
    }
    else if(!agree) {
      notify("You must be agree with the Privacy Policy") ;
      valid = false ;
    }

    if(valid) {

    }
  }




  const openBlockListBar = () => {
    $(".all_blocklist_bar").css({"display" : "flex "})   ;  
    }





  const goToLogin = () => {
    history.push("/auth/login");
  }
  

  
  

    return (
      
      <Container className="mt-1 p-0 acount_settings_space" fluid>
      <Row>

        <Col className="mb-5 mb-xl-0 mx-auto  p-0" xs="12" style={{marginTop : "30px" }}>
          <Card className="card-profile shadow" style={{ borderRadius: "10px"}}>
            <CardHeader className=" border-0 mt-4 p-0 px-4 d-flex justify-content-start align-items-center" 
                          style={{height : "64px" , overflow : "hidden"  , borderRadius: "10px" , fontSize:"32px" , fontWeight : "600" , color : "black"}}>
                Settings    
            </CardHeader>
            
            <CardBody className="m-0 px-4 pt-0 card_body_space" >
              <Form role="form" >

                <div className='w-100 row ml-0 p-0 m-0 '>
                      <div className="col-12 text_login2 text-center mt-2 mb-3 d-flex justify-content-center align-items-center">
                        <hr/>
                        <span>Public informaitons </span>
                      </div>


                      <div className='col-12 col-md-6'>
                        <div className="settings_label">
                          Fullame
                        </div>
                        <FormGroup>
                            <InputGroup className="input-group-alternative" 
                                        style={{  background: "#eef1ee" , borderRadius: "20px" ,  overflow: "hidden" , 
                                        boxShadow: "none" , border: "none", transition: "none", }}>
                              
                              <Input placeholder="" type="text"
                                onChange={e => setFullname(e.currentTarget.value)}
                                className="pl-4"
                                defaultValue={"Christina Hodland"}
                                style={{background : "#eef1ee" ,  color : "#919191"}}
                                />
                            </InputGroup>
                          </FormGroup>
                      </div>
                      <div className='col-12 col-md-6'>
                        <div className="settings_label">
                        Username
                        </div>
                        <FormGroup>
                          <InputGroup className="input-group-alternative" 
                                      style={{  background: "#eef1ee" , borderRadius: "20px" ,  overflow: "hidden" , 
                                      boxShadow: "none" , border: "none", transition: "none", }}>
                            
                            <Input placeholder="" type="text"  
                                  onChange={e => setUsername(e.currentTarget.value)}
                                  className="pl-4"
                                  defaultValue={"Christina_Hodland"}
                                  style={{background : "#eef1ee" ,  color : "#919191"}}
                                  />
                          </InputGroup>
                        </FormGroup>
                      </div>


                      <div className='col-12 col-md-6 row ml-0 p-0 m-0'>
                        <div className='col-12 '>
                          <div className="settings_label">
                          Website/link
                          </div>
                          <FormGroup>
                            <InputGroup className="input-group-alternative" 
                                        style={{  background: "#eef1ee" , borderRadius: "20px" ,  overflow: "hidden" , 
                                        boxShadow: "none" , border: "none", transition: "none", }}>
                              
                              <Input placeholder="" type="text"  
                                    onChange={e => setUsername(e.currentTarget.value)}
                                    className="pl-4"
                                    defaultValue={"linktr.ee/Christina_Hodland"}
                                    style={{background : "#eef1ee" ,  color : "#919191"}}
                                    />
                            </InputGroup>
                          </FormGroup>
                        </div>

                        <div className='col-12 '>
                          <div className="settings_label">
                            Wallet adress
                          </div>
                          <FormGroup>
                            <InputGroup className="input-group-alternative" 
                                        style={{  background: "#eef1ee" , borderRadius: "20px" ,  overflow: "hidden" , 
                                        boxShadow: "none" , border: "none", transition: "none", }}>
                              
                              <Input placeholder="" type="text"  
                                    onChange={e => setUsername(e.currentTarget.value)}
                                    className="pl-4"
                                    defaultValue={"0x75d4...346F"}
                                    style={{background : "#eef1ee" ,  color : "#919191"}}
                                    />
                            </InputGroup>
                          </FormGroup>
                        </div>
                        
                      </div>

                      <div className='col-12 col-md-6'>
                        <div className="settings_label">
                        Bio
                        </div>
                        <FormGroup>
                          <InputGroup className="input-group-alternative" 
                                      style={{  background: "#eef1ee" , borderRadius: "20px" ,  overflow: "hidden" , 
                                      boxShadow: "none" , border: "none", transition: "none", }}>
                            
                            <Input placeholder="" 
                                  type="textarea"
                                  onChange={e => setUsername(e.currentTarget.value)}
                                  className="pl-4"
                                  defaultValue={"Author, Poet \nMom and nature lover \nLinks to my Esty poetry shop and book below"}
                                  style={{background : "#eef1ee" ,  color : "#919191", minHeight : "100px" }}
                                  />
                          </InputGroup>
                        </FormGroup>
                      </div>
                    

                    <div className="col-12 text_login2 text-center mt-2 mb-3 d-flex justify-content-center align-items-center">
                      <hr/>
                      <span>Add your socials </span>
                    </div>

                    <div className="col-12 row ml-0 d-flex justify-content-center ">
                      <img
                          alt="..."
                          className="m-3"
                          style={{width : "40px" , height : "auto" , cursor : "pointer"}}
                          src={
                            facebook
                          }
                      />
                      <img
                          alt="..."
                          className="m-3"
                          style={{width : "40px" , height : "auto" , cursor : "pointer"}}
                          src={
                            insta
                          }
                      />
                      <img
                          alt="..."
                          className="m-3"
                          style={{width : "40px" , height : "auto" , cursor : "pointer"}}
                          src={
                            twitter
                          }
                      />
                      <img
                          alt="..."
                          className="m-3"
                          style={{width : "40px" , height : "auto" , cursor : "pointer"}}
                          src={
                            tiktok
                          }
                      />
                      <img
                          alt="..."
                          className="m-3"
                          style={{width : "40px" , height : "auto" , cursor : "pointer"}}
                          src={
                            youtube
                          }
                      />
                      <img
                          alt="..."
                          className="m-3"
                          style={{width : "40px" , height : "auto" , cursor : "pointer"}}
                          src={
                            linkedIn
                          }
                      />

                    </div>

                    <div className="col-12 text_login2 text-center mt-2 mb-3 d-flex justify-content-center align-items-center">
                      <hr/>
                      <span>Private informaitons </span>
                    </div>
                    <div className='col-12 col-md-6'>
                          <div className="settings_label">
                            Email
                          </div>
                          <FormGroup>
                            <InputGroup className="input-group-alternative" 
                                        style={{  background: "#eef1ee" , borderRadius: "20px" ,  overflow: "hidden" , 
                                        boxShadow: "none" , border: "none", transition: "none", }}>
                              
                              <Input
                                placeholder=""
                                type="email"
                                onChange={e => setEmail(e.currentTarget.value)}
                                className="pl-4"
                                defaultValue={"Christinahodland@gmail.com"}
                                style={{background : "#eef1ee" ,  color : "#919191"}}
                                // {...settings("email")}
                              />
                            </InputGroup>

                          </FormGroup> 
                      </div>

                      <div className='col-12 col-md-6'>
                        <div className="settings_label">
                          Gender
                        </div>
                        <FormGroup>
                          <InputGroup className="input-group-alternative" 
                                      style={{  background: "#eef1ee" , borderRadius: "20px" ,  overflow: "hidden" , 
                                      boxShadow: "none" , border: "none", transition: "none" }}>
                            
                            <Input
                                id="exampleSelectMulti"
                                className="form-control-alternativepl-4 pl-4 gender_input_select"
                                name="gender"
                                type="select"
                                placeholder=''
                                defaultValue={"Female"}
                                onChange={e => {return setGender(e.currentTarget.value)}}
                                style={{ color : "#919191"}}
                                
                                // {...settings("gender")}
                                >
                                  
                                <option value="Male">
                                    Male
                                </option>
                                <option value="Female">
                                    Female
                                </option>
                                
                            </Input>
                            
                          </InputGroup>
                        </FormGroup>
                      </div>

                      {!changePassword && 
                        <div className='col-12 col-md-6'>
                          <div className="settings_label">
                            Password
                          </div>
          
                          <FormGroup>
                            <InputGroup className="input-group-alternative" 
                                        style={{  background: "#eef1ee" , borderRadius: "20px" ,  overflow: "hidden" , 
                                        boxShadow: "none" , border: "none", transition: "none", }}>
                              
                              <Input
                                placeholder=""
                                defaultValue={"password"}
                                type={showPassword ? "text" : "password"}
                                onChange={e => setPassword(e.currentTarget.value)}
                                className="pl-4"
                                style={{background : "#eef1ee" ,  color : "#000000"}}
                                // {...settings("password")}
                              />
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText style={{background : "#eef1ee"}}>
                                    <span style={{ color : "#e30613" , cursor : "pointer"}} onClick={()=> {setChangePassword(true)}}>
                                      Change Password
                                      </span>
                                  </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>  
                        </div>
                      
                      }



                      {changePassword && 
                        <div className='col-12 col-md-6'>
                          <div className="settings_label">
                            Current Password
                          </div>
          
                          <FormGroup>
                            <InputGroup className="input-group-alternative" 
                                        style={{  background: "#eef1ee" , borderRadius: "20px" ,  overflow: "hidden" , 
                                        boxShadow: "none" , border: "none", transition: "none", }}>
                              
                              <Input
                                placeholder=""
                                type={showPassword ? "text" : "password"}
                                onChange={e => setPassword(e.currentTarget.value)}
                                className="pl-4"
                                style={{background : "#eef1ee" ,  color : "#000000"}}
                                // {...settings("password")}
                              />
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{background : "#eef1ee"}}>
                                    <div  style={{width : "25px" }}>
                                        <img
                                            alt="..."
                                            style={{width : "17px" , height : "auto" , cursor : "pointer"}}
                                            src={
                                              hidden_password
                                            }
                                            onClick={set_ShowPassword}
                                        />
            
                                    </div>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>  
                        </div>
                      
                      }

                      {changePassword && 
                        <div className='col-12 col-md-6'> 
                        </div>
                      
                      }


                      {changePassword && 
                        <div className='col-12 col-md-6'>
                          <div className="settings_label">
                            New Password
                          </div>
          
                          <FormGroup>
                            <InputGroup className="input-group-alternative" 
                                        style={{  background: "#eef1ee" , borderRadius: "20px" ,  overflow: "hidden" , 
                                        boxShadow: "none" , border: "none", transition: "none", }}>
                              
                              <Input
                                placeholder=""
                                type={showPassword ? "text" : "password"}
                                onChange={e => setPassword(e.currentTarget.value)}
                                className="pl-4"
                                style={{background : "#eef1ee" ,  color : "#000000"}}
                                // {...settings("password")}
                              />
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{background : "#eef1ee"}}>
                                    <div  style={{width : "25px" }}>
                                        <img
                                            alt="..."
                                            style={{width : "17px" , height : "auto" , cursor : "pointer"}}
                                            src={
                                              hidden_password
                                            }
                                            onClick={set_ShowPassword}
                                        />
            
                                    </div>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>  
                        </div>
                      
                      }

                      {changePassword && 
                        <div className='col-12 col-md-6'>
                          <div className="settings_label">
                            Confirm Password
                          </div>
          
                          <FormGroup>
                            <InputGroup className="input-group-alternative" 
                                        style={{  background: "#eef1ee" , borderRadius: "20px" ,  overflow: "hidden" , 
                                        boxShadow: "none" , border: "none", transition: "none", }}>
                              
                              <Input
                                placeholder=""
                                type={showPassword ? "text" : "password"}
                                onChange={e => setPassword(e.currentTarget.value)}
                                className="pl-4"
                                style={{background : "#eef1ee" ,  color : "#000000"}}
                                // {...settings("password")}
                              />
                            </InputGroup>
                          </FormGroup>  
                        </div>
                      
                      }


                      <div className="col-12 text_login3  m-0 mb-2 d-flex justify-content-start align-items-center"
                            style={{fontSize : "13px" , color : "gray"}}>
                        <span >Your personal information won't be shown on your public profile</span>
                      </div>


                      <div className="col-12 text_login2 text-center mt-0 mb-3 d-flex justify-content-center align-items-center"
                          style={{height : "5px"}}>
                        <hr/>
                      </div>

                      <div className="col-12 row ml-0 p-0 m-0"  style={{ textAlign : "left " , color : "black"}}>
                          <div className="col-12 py-2" style={{fontSize : '16px'}} >
                              Private account 
                          </div>
                          <div className="col-12 col-md-8"  style={{fontSize : '13px' , fontWeight : "300"}}>
                            By turning your account private , only people you approve can see your posts/NFTs ans stories on Punchword
                          </div>
                          <div className="col-12 col-md-4 d-flex justify-content-end align-items-center ">
                            <FormGroup>
                              <FormControlLabel
                                  control={<IOSSwitch sx={{ m: 1 }}  />} 
                                />
                            </FormGroup>

                          </div>
                          <div className="col-12 mt-5 py-2"  style={{fontSize : '16px'}} >
                              Block list  
                          </div>
                          <div className="col-12 col-md-8"  style={{fontSize : '14px'  , fontWeight : "300"}}>
                              By blocking someone , they won't be able to text you , view your posts and stories , their likes and comments on your profile we'll be deleted
                              <br/>
                              People aren't notified when you block them 
                          </div>
                          <div className="col-12 col-md-4 d-flex justify-content-end align-items-center "  style={{fontSize : '14px'}}>
                                <span style={{ color : "#e30613" , cursor : "pointer"}} onClick={()=> {openBlockListBar()}} >SEE LIST</span>
                          </div>
                          <div className="col-12"  style={{ marginTop : "100px" , fontSize : '16px'}} >
                              <span style={{ color : "#e30613" , cursor : "pointer"}}>Delete my account </span>
                          </div>
                          
                      </div>


                      
                </div>
                

                {/* <div className="text-muted font-italic">
                  <small>
                    password strength:{" "}
                    <span className="text-success font-weight-700">strong</span>
                  </small>
                </div> */}
                <div className="text-center mb-3 d-flex justify-content-end align-items-center">
                  
                  <div  className={ (valid ? "settings_settings_btn_valid" : "")+" settings_settings_btn d-flex justify-content-center align-items-center"} onClick={valid ? signUp : null}>
                      SAVE
                  </div>
                </div>
                
              </Form>
             
             
            </CardBody>
          </Card>
        </Col>
        
        
        </Row>
        
        <BlockListBar></BlockListBar>

      </Container>
    );
  };
  
  export default ProfileSettings;
  