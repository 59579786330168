import 'react-toastify/dist/ReactToastify.css';
import LandAmazingFeatures from './components/LandAmazingFeatures';
import LandComingSoon from './components/LandComingSoon';
import LandContact from './components/LandContact';
import LandCreatePunchword from './components/LandCreatePunchword';
import LandDownload from './components/LandDownload';
import LandDownloadBeta from './components/LandDownloadBeta';
import Bitmap from './components/Bitmap';
import LandFooter from './components/LandFooter';
import Punchword from './components/Punchword';
import PunchyToken from './components/PunchyToken';

  
  const LandingPage = () => {
    
  
  
  
    return (
      <>
        <Bitmap></Bitmap>
        <Punchword></Punchword>
        {/* <PunchyToken></PunchyToken> */}
        <LandDownload></LandDownload>
        <LandCreatePunchword></LandCreatePunchword>
        <LandAmazingFeatures></LandAmazingFeatures>
        <LandComingSoon></LandComingSoon>
        <LandDownloadBeta></LandDownloadBeta>
        <LandContact></LandContact>
        <LandFooter></LandFooter>
      </>
    );
  };
  
  export default LandingPage;
  