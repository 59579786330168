import React from "react";

import {
    Col,
    Row,
    Container,
    Form,
    FormGroup,
    InputGroup,
    Input,
  } from "reactstrap";
  
import './LandContact.scss' ;



//icons 
// import phones from '../../assets/img/icons/LandContact/phones.svg' ;
// import line2 from '../../assets/img/icons/LandContact/line2.svg' ;
// import arrow from '../../assets/img/icons/LandContact/arrow.svg' ;
// import share from '../../assets/img/icons/post/share.svg' ;
// import details from '../../assets/img/icons/post/details.svg' ;


  const LandContact = (props) => {

  
  
    return (
          <>
            <div className="w-100 p-0 all_land_contact d-flex  justify-content-center align-items-center" id="ContactUs">
              <Container className="land_contact_container ">
                <Row className="land_contact_row w-100 ml-0">
                  <Col xs="12" md="6" className="d-flex  justify-content-center align-items-start">
                    <div  style={{maxWidth : "600px" , width : "100%"}}>
                        <div className="land_contact_text1">
                            Contact us 
                        </div>
                        <div className="land_contact_text2">
                          For any question, comment, technical issue, improvement ideas please fill the following form.
                        </div>
                        <div className="land_contact_text2" style={{marginTop : "70px" , marginBottom : "100px"}}>
                        “The single biggest problem in communication is the illusion that it has taken place.” <br/> -George Bernard Shaw
                        </div>

                    </div>

                    
                  </Col>
                  <Col xs="12" md="6" >
                    <div className="w-100 d-flex  justify-content-center align-items-center">
                      <Form role="form w-100" style={{maxWidth : "600px" , width : "100%"}}>
                        <div className="land_contact_label w-100  px-2">
                          Your name
                        </div>
                        <div className="land_contact_label_in w-100">
                          
                          <FormGroup className="my-1" >
                            <InputGroup className="input-group-alternative" 
                                        style={{  background: "#E30613" , borderRadius: "20px" ,  overflow: "hidden" , 
                                        boxShadow: "none" , border: "1px solid white", transition: "none", }}>
                              
                              <Input
                                placeholder=""
                                type="text"
                                style={{background : "#E30613" ,  color : "white"}}
                                // autoComplete="new-email"
                                // onChange={e => setEmail(e.currentTarget.value)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </div>



                        <div className="land_contact_label w-100 mt-3 px-2 mt-2">
                          Your email
                        </div>
                        <div className="land_contact_label_in w-100">
                          
                          <FormGroup className="my-1" >
                            <InputGroup className="input-group-alternative" 
                                        style={{  background: "#E30613" , borderRadius: "20px" ,  overflow: "hidden" , 
                                        boxShadow: "none" , border: "1px solid white", transition: "none", }}>
                              
                              <Input
                                placeholder=""
                                type="email"
                                style={{background : "#E30613" ,  color : "white"}}
                                // autoComplete="new-email"
                                // onChange={e => setEmail(e.currentTarget.value)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </div>

                        <div className="land_contact_label w-100 mt-3 px-2 mt-2">
                          Your Message
                        </div>
                        <div className="land_contact_label_in w-100">
                          
                          <FormGroup className="my-1" >
                            <InputGroup className="input-group-alternative" 
                                        style={{  background: "#E30613" , borderRadius: "20px" ,  overflow: "hidden" , 
                                        boxShadow: "none" , border: "1px solid white", transition: "none", }}>
                              
                              <Input
                                placeholder=""
                                type="textarea"
                                style={{background : "#E30613" ,  color : "white" , height : "300px" }}
                                // autoComplete="new-email"
                                // onChange={e => setEmail(e.currentTarget.value)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </div>


                        
                        <div  className="font-weight-600 land_contact_btn d-flex justify-content-center align-items-center">
                                SUBMIT
                        </div>
                    </Form>

                    </div>
                    
                  </Col>
                    
                </Row>

              </Container>
            </div>
          </>
    );
  };
  
  export default LandContact;
  