/*!

=========================================================
* Punchword - v0.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

import punch_logo from "../../assets/img/icons/logo.svg";
import "./AuthNavbar.scss";
import { Link as Link2 } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";

const AdminNavbar = () => {
  return (
    <>
      <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
        <Container className="px-4 nav_bar_container">
          <NavbarBrand>
            <div className="d-flex justify-content-center align-items-center">
              <Link
                to="Whitepaper"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <img alt="..." style={{ cursor: "pointer" }} src={punch_logo} />
              </Link>
              <h6 className="m-0 ml-2 text-dark" style={{ fontSize: "9px " }}>
                PUNCHWORD
              </h6>
            </div>
          </NavbarBrand>
          <button className="navbar-toggler" id="navbar-collapse-main">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link
                    to="Whitepaper"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    <img
                      alt="..."
                      style={{ cursor: "pointer" }}
                      src={punch_logo}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar-collapse-main">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>
              {/* <NavItem className="d-flex justify-content-center align-items-center">
                <NavLink className="nav-link-icon">
                  <Link 
                      className="nav-link-inner--text" 
                      to="PunchyToken"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}>
                        Punchy token
                  </Link>
                  
                </NavLink>
              </NavItem> 
              */}

              <NavItem className="d-flex justify-content-center align-items-center">
                <NavItem className="d-flex justify-content-center align-items-center">
                  <NavLink className="nav-link-icon">
                    {/* <i className="ni ni-circle-08" /> */}
                    <Link
                      className="nav-link-inner--text"
                      to="Whitepaper"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      Bitmart
                    </Link>
                  </NavLink>
                </NavItem>

                <NavLink className="nav-link-icon">
                  {/* <i className="ni ni-single-02" /> */}
                  {/* <i className="fa fa-phone" aria-hidden="true"></i> */}
                  <Link
                    className="nav-link-inner--text"
                    to="Features"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    Features
                  </Link>
                </NavLink>
              </NavItem>

              <NavItem className="d-flex justify-content-center align-items-center">
                <NavLink className="nav-link-icon">
                  {/* <i className="ni ni-circle-08" /> */}
                  <Link
                    className="nav-link-inner--text"
                    to="NewVersion"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    New Version
                  </Link>
                </NavLink>
              </NavItem>

              <NavItem className="d-flex justify-content-center align-items-center">
                <NavLink className="nav-link-icon">
                  {/* <i className="ni ni-circle-08" /> */}
                  <Link
                    className="nav-link-inner--text"
                    to="Download"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    Download
                  </Link>
                </NavLink>
              </NavItem>

              <NavItem className="d-flex justify-content-center align-items-center">
                <NavLink className="nav-link-icon">
                  {/* <i className="ni ni-circle-08" /> */}
                  <Link
                    className="nav-link-inner--text"
                    to="ContactUs"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    Contact Us
                  </Link>
                </NavLink>
              </NavItem>

              {/* <NavItem className="d-flex justify-content-center align-items-center">
                <NavLink className="nav-link-icon p-0 d-flex justify-content-center align-items-center" 
                          // to="/auth/login" tag={Link2}
                >
                <div  className="font-weight-600 login_btn d-flex justify-content-center align-items-center">
                        LOGIN
                </div>

                </NavLink>
              </NavItem> */}
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
