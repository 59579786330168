import React from "react";

import {
    Row,
    Container,
  } from "reactstrap";
  
import './LandAmazingFeatures.scss' ;



//icons 
import line from '../../../../assets/img/icons/LandAmazingFeatures/line.svg' ;
import image1 from '../../../../assets/img/icons/LandAmazingFeatures/1.svg' ;
import image2 from '../../../../assets/img/icons/LandAmazingFeatures/2.svg' ;
import image3 from '../../../../assets/img/icons/LandAmazingFeatures/3.svg' ;
import image4 from '../../../../assets/img/icons/LandAmazingFeatures/4.svg' ;
import image5 from '../../../../assets/img/icons/LandAmazingFeatures/5.svg' ;
import image6 from '../../../../assets/img/icons/LandAmazingFeatures/6.svg' ;


  const LandAmazingFeatures = (props) => {

  
  
    return (
          <>
          <div className="w-100 p-0 m-0 d-flex justify-content-center align-items-center" style={{minHeight : "calc( 100vh - 100px )"}} id="Features">
        
            <Container className="container_amazing_features">
                <div className=" w-100 p-0  d-flex justify-content-center align-items-center" style={{marginBottom : "50px"}} >
                        <div className="punchytoken_text1  text-center"  style={{background : "white"}}>
                        Amazing features

                        </div>
                </div>

                <Row className="row_amazing_features ">
                    <div className="background_row_amazing_features">
                        <img src={
                            line
                        }
                        alt=''
                        />
                    </div>

                    <div className="col-12 col-md-6  col-lg-4 mb-3 d-flex justify-content-center align-items-center ">
                        <div className="w-100 land_amazing_features_item" >
                            
                            <img src={  image1 } style={{  height : "80px" }} alt="" />
                            <div className="punchytoken_text3">
                                Punchword Library
                            </div>
                            <div className="punchytoken_text4">
                                Have access to the Punchword Quotes and Books passages library and select the most impacting ones
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-md-6  col-lg-4 mb-3 d-flex justify-content-center align-items-center ">
                        <div className="w-100 land_amazing_features_item" >
                            <img src={  image2 } style={{  height : "80px" }} alt="" />
                            
                            <div className="punchytoken_text3"  style={{width : "90%"}}>
                                Templates

                            </div>
                            <div className="punchytoken_text4">
                                 Punchword's templates are regularly updated so that you can highlight your creations and your pictures.
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-md-6  col-lg-4 mb-3 d-flex justify-content-center align-items-center ">
                        <div className="w-100 land_amazing_features_item" >
                            
                            <img src={  image3 } style={{  height : "80px" }} alt="" />
                            <div className="punchytoken_text3"  style={{width : "90%"}}>
                                Community
                            </div>
                            <div className="punchytoken_text4">
                                Join the words' lovers community by chatting and sharing your words with other punchword users.
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-md-6  col-lg-4 mb-3 d-flex justify-content-center align-items-center ">
                        <div className="w-100 land_amazing_features_item" >
                            
                            <img src={  image4 } style={{  height : "80px" }} alt="" />
                            <div className="punchytoken_text3" style={{width : "90%"}}>
                                Punchword
                            </div>
                            <div className="punchytoken_text4">
                                Create amazing punchwords using our templates and library, or simply share your photos.
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6  col-lg-4 mb-3 d-flex justify-content-center align-items-center ">
                        <div className="w-100 land_amazing_features_item" >
                            
                            <img src={  image5 } style={{  height : "80px" }} alt ="" />
                            <div className="punchytoken_text3" style={{width : "90%"}}>
                                Punchvideo
                            </div>
                            <div className="punchytoken_text4">
                                You can edit your videos and share them with your network.
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6  col-lg-4 mb-3 d-flex justify-content-center align-items-center ">
                        <div className="w-100 land_amazing_features_item" >
                            
                            <img src={  image6 } style={{  height : "80px" }} alt="" />
                            <div className="punchytoken_text3" style={{width : "90%"}}>
                                Sound effects
                            </div>
                            <div className="punchytoken_text4">
                                Record your voice on top of your punchwords or punchvideos, and apply the sound effect you want.
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
            </div>
          </>
    );
  };
  
  export default LandAmazingFeatures;
  