import React, { useEffect, useState } from "react";

import { Col, Row, Container } from "reactstrap";

import "./LandFooter.scss";

//icons
import white_logo from "../../../../assets/img/icons/LandFooter/white_logo.svg";
import insta from "../../../../assets/img/icons/LandFooter/insta.svg";
import telegram from "../../../../assets/img/icons/LandFooter/telegram.png";
import facebook from "../../../../assets/img/icons/LandFooter/facebook.svg";
import twitter from "../../../../assets/img/icons/LandFooter/twitter.svg";
import { Link } from "react-scroll";

const LandFooter = (props) => {
  const goToFacebook = () => {
    window
      .open("https://www.facebook.com/profile.php?id=100049129212050", "_blank")
      .focus();
  };

  const goToTweeter = () => {
    window.open("https://twitter.com/Punchword_off", "_blank").focus();
  };

  const goToInsta = () => {
    window
      .open("https://www.instagram.com/punchword_officiel/", "_blank")
      .focus();
  };

  const goToTelegram = () => {
    window.open("https://t.me/PunchyToken/", "_blank").focus();
  };

  const [currentYear, setCurrentYear] = useState("");

  useEffect(() => {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    setCurrentYear(year);
  }, []);

  return (
    <>
      <div className="w-100 p-0 all_land_footer d-flex  justify-content-center align-items-center">
        <Container className="land_footer_container ">
          <Row className="land_footer_row w-100 ml-0">
            <Col
              xs="12"
              md="4"
              className="d-flex  justify-content-center align-items-start"
            >
              <div style={{ maxWidth: "600px" }}>
                <Link
                  className="nav-link-inner--text"
                  to="Punchword"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <img
                    className="logo_footer_image"
                    alt=""
                    src={white_logo}
                    style={{
                      height: "100px",
                      cursor: "pointer",
                    }}
                  />
                </Link>
              </div>
            </Col>
            <Col
              xs="12"
              md="4"
              className="d-flex  justify-content-center align-items-start"
            >
              <div style={{ maxWidth: "250px", width: "100%" }}>
                <div className="land_footer_text2 text-center">Follow us</div>
                <div className="d-flex justify-content-around align-items-start mt-3 ">
                  <img
                    className="icon_footer_image"
                    alt=""
                    src={twitter}
                    style={{
                      height: "40px",
                      merginRight: "20px",
                      cursor: "pointer",
                    }}
                    onClick={goToTweeter}
                  />
                  <img
                    className="icon_footer_image"
                    alt=""
                    src={insta}
                    style={{
                      height: "40px",
                      merginRight: "20px",
                      cursor: "pointer",
                    }}
                    onClick={goToInsta}
                  />
                  <img
                    className="icon_footer_image"
                    alt=""
                    src={facebook}
                    style={{
                      height: "40px",
                      cursor: "pointer",
                    }}
                    onClick={goToFacebook}
                  />
                  <img
                    className="icon_footer_image"
                    alt=""
                    src={telegram}
                    style={{
                      height: "40px",
                      cursor: "pointer",
                    }}
                    onClick={goToTelegram}
                  />
                </div>
              </div>
            </Col>
            <Col
              xs="12"
              md="4"
              className="d-flex  justify-content-center align-items-start"
            >
              <div style={{ maxWidth: "600px" }}>
                <div className="land_footer_text2  text-center">
                  Connect with us
                </div>
                <div className="land_footer_text2  text-center mt-3">
                  contact@punchword.com
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="w-100 bottom_footer_div d-flex  justify-content-center align-items-start">
        <div className="font-weight-600 land_footer_text2">
          Punchword {currentYear} ©
        </div>
      </div>
    </>
  );
};

export default LandFooter;
