import React from "react";
import { useLocation, useHistory } from "react-router-dom";
// reactstrap components
import { Row } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";

import { auth } from "app/firebase";

import './Auth.scss' ;
import LandingPage from "views/pages/LandingPage/LandingPage";

const Home = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();


  const  history = useHistory();

  React.useEffect(() => {
    auth.onAuthStateChanged(user => {
       if (user) history.push('/user')
    })
 }, [history]) 

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);
  

  return (
    <>
      <div className="main-content bg-white h-100" style={{minHeight : '100vh' , overflow : 'hidden'}} ref={mainContent}>
        <AuthNavbar />
        <div className="w-100  py-0">
          <Row className="w-100 ml-0 justify-content-center">
            <LandingPage></LandingPage>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Home;
