
import React, {  useState } from "react";

import {
  Button,
  Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
  } from "reactstrap";
import MarketItem from "../NftMarket/MarketItem/MarketItem";
  

import user_img from '../../../../assets/img/theme/user_punchy.png' ;
import back_img from '../../../../assets/img/theme/test_back.jpg' ;
import separator from '../../../../assets/img/icons/post/separator.svg' ;

import details from '../../../../assets/img/icons/post/details.svg' ;
import address from '../../../../assets/img/icons/Profile/icons/address.svg' ;
import insta_icon from '../../../../assets/img/icons/Profile/icons/insta_icon.svg' ;
import edit_photo from '../../../../assets/img/icons/Profile/icons/edit_photo.svg' ;

//images
import post_img1 from '../../../../assets/img/icons/Profile/imgs/post_img1.svg' ;
import post_img2 from '../../../../assets/img/icons/Profile/imgs/post_img2.svg' ;
import post_img3 from '../../../../assets/img/icons/Profile/imgs/post_img3.svg' ;
import post_img4 from '../../../../assets/img/icons/Profile/imgs/post_img4.svg' ;

//video
import video_img from '../../../../assets/img/icons/Profile/imgs/video_img.svg' ;
import video_img2 from '../../../../assets/img/icons/Profile/imgs/video_img2.png' ;
  
import './AccountProfile.scss' ;
import Post from "../Home/Post/Post";
import ProfilePostItem from "./components/ProfilePostItem/ProfilePostItem";
import { alpha, Divider, Menu, MenuItem, styled } from "@mui/material";
import Fade from '@mui/material/Fade';
import { useHistory } from "react-router-dom";


import { db } from "app/firebase";
import { collection, getDocs, orderBy, query, where , updateDoc, setDoc , doc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { toast, ToastContainer } from "react-toastify";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


const AccountProfile = (session) => {

    console.log("AccountProfile session");
    console.log(session);
    
    
    const  history = useHistory();
    const [posts , setPosts] = useState([]) ;

    const [select , setSelect] = useState(1) ;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

  

  var current_user = localStorage.getItem("user") ;

  if(current_user && current_user !== "") {
    current_user = JSON.parse(current_user) ;
  }else {
    current_user = null ;
  } ;

  
  


  const getPostsVideo = async() => {
    var posts = [] ;
   const q = query(collection(db, "Posts"), where("uid", "!=", current_user.uid), orderBy("uid"), orderBy("createdAt", "desc"));
   const querySnapshot = await getDocs(q);

   querySnapshot.forEach((doc) => {
    posts.push(doc.data());
   });


   const q2 = query(collection(db, "Videos"), where("uid", "!=", current_user.uid), orderBy("uid"), orderBy("createdAt", "desc"));
   const querySnapshot2 = await getDocs(q2);

   querySnapshot2.forEach((doc) => {
    posts.push(doc.data());
   });

   setPosts(posts) ;
   
  }
  if(posts.length === 0) {
    getPostsVideo() ;
  }


  const postList = [
    {
      type : "video" , 
      thumbnailUrl : video_img , 
      url : "" 
    } ,
    {
      type : "image" , 
      url : post_img1 
    } , 
    {
      type : "image" , 
      url : post_img2 
    } , 
    {
      type : "post" , 
      text : "<div>Links to my Esty poetry shop and book below</div> <a class='post_link'>linktr.ee/Christy_and_martine</a>" 
    } , 
    {
      type : "image" , 
      url : post_img3 
    } , 
    {
      type : "image" , 
      url : post_img4 
    } , 

    {
      type : "post" , 
      text : "<div>My 'Flower in the Desert ' poem describes my journey of healing from childhood emotional and physical abuse . My greatest hope is that my words will comfort and inspire trauma survivors who need</div> " 
    } , 
    {
      type : "post" , 
      text : "<div>My 'Flower in the Desert ' poem describes my journey of healing from childhood emotional and physical abuse . My greatest hope is that my words will comfort and inspire trauma survivors who need</div> " 
    } , 
    {
      type : "video" , 
      thumbnailUrl : video_img2 , 
      url : "" 
    } ,
    {
      type : "post" , 
      text : "<div>My 'Flower in the Desert ' poem describes my journey of healing from childhood emotional and physical abuse . My greatest hope is that my words will comfort and inspire trauma survivors who need</div> " 
    } 
  ]
  

  const nftList = [
    {
      type : "video" , 
      thumbnailUrl : video_img2 , 
      url : "" 
    } ,
    {
      type : "image" , 
      url : post_img1 
    } , 
    {
      type : "image" , 
      url : post_img2 
    } , 
    {
      type : "image" , 
      url : post_img3 
    } , 
    {
      type : "post" , 
      text : "<div>My 'Flower in the Desert ' poem describes my journey of healing from childhood emotional and physical abuse . My greatest hope is that my words will comfort and inspire trauma survivors who need</div> " 
    } 
  ]

  const hiddenList = []



  const renderPosts = (listTorend) => {
    var list = [] ; 

    listTorend.forEach(post => {
        list.push(
          <ProfilePostItem item={post}  />
        ) ;
    });

    return list ;
  }

  const renderMarketItems = () => {
    var list = [] ; 

    posts.forEach(post => {
        list.push(
          <Col className="order-xl-2 mb-5 mb-xl-0 mx-auto  p-0" xl="12">
            <MarketItem  post={post}/>
          </Col>
        ) ;
    });

    return list ;
  }

    var popular_list_type = 0 ;
    var market_list_type = 0 ;
    
    // const dispatch = useDispatch() ; 
    
  
    // const userName = useSelector(selectUserName) ;
    // const userEmail = useSelector(selectUserEmail) ;
  
    
  const goToSettings = () => {
    history.push("/user/settings");
  }
  const goToFavorites = () => {
    history.push("/user/favorites");
  }
  const goToOffers = () => {
    history.push("/user/offers");
  }


  const hiddenFileInput = React.useRef(null);

  const handleClickEditProfilePic = event => {
    hiddenFileInput.current.click();
  };

   
  const handleChange = event => {
    const fileUploaded = event.target.files[0];

    const storage = getStorage();

    const storageRef = ref(storage, "/images/"+current_user.uid+"_"+fileUploaded.name);

    const uploadTask = uploadBytesResumable(storageRef, fileUploaded);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on('state_changed', 
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        notify('Upload is ' + progress + '% done')
        
      }, 
      (error) => {
        // Handle unsuccessful uploads
      }, 
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then(async(fireBaseUrl) => {
          current_user.imageURL = fireBaseUrl ; 

          const userRef = query(collection(db, "Users"), where("uid", "==", current_user.uid));
          const findUsers = await getDocs(userRef);
          findUsers.forEach( async (user) => {
          const getUser = doc(db, 'Users', user.id);
          await updateDoc(getUser, {imageURL : fireBaseUrl}).then((res) => {
              console.log(res);
              localStorage.setItem("user" , JSON.stringify(current_user)) ;
              history.push('/user/profile')
          });
          });
        });
      }
    );


    
    // props.handleFile(fileUploaded);
  };


  const notify = (message) => {
    
    toast.error(message, {
      position: "bottom-right",
      autoClose: 1000 ,
      type : "success"
      });
  }
  
  
    return (
      
      <Container className="mt-1 p-0 acount_profile_space" fluid>
      <Row>

        <Col className="mb-5 mb-xl-0 mx-auto  p-0" xs="12" style={{marginTop : "30px" }}>
          <Card className="card-profile shadow" style={{ borderRadius: "10px"}}>
            <CardHeader className="text-center border-0 p-0 d-flex justify-content-center align-items-center profile_back_header" 
                          style={{height : "300px" , overflow : "hidden"  , borderRadius: "10px" , position : "relative"}}>
              <img
                  alt="..."
                  className="image_back_card_profile"
                  src={
                      back_img
                  }
              />
              <div className="back_change_photo">
                  <img
                      alt="..."
                      style={{width : "70px" , height : "auto"}}
                      src={
                        edit_photo
                      }
                 />
              </div>
            </CardHeader>
            <CardBody className="m-0 py-0 card_body_space" >
              <div className="row ml-0 w-100" style={{marginTop : "-40px"}}>
                  <div className="col-12 col-md-6 d-flex p-0">
                    <div className="card_profile_image d-flex justify-content-center align-items-center" style={{width : "130px"}}>
                        <div className="card_profile_image_div">
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={
                              current_user && current_user.imageURL !== "" ? current_user.imageURL : user_img
                            }
                          />
                          <div className="profile_change_photo">
                              <img
                                  alt="..."
                                  style={{width : "30px" , height : "auto"}}
                                  src={
                                    edit_photo
                                  }
                                  onClick={handleClickEditProfilePic}
                            />
                            <input
                                type="file"
                                ref={hiddenFileInput}
                                onChange={handleChange}
                                style={{display: 'none'}}
                              />

                          </div>

                        </div>


                        {/* <div className="badge">
                            LIVE
                        </div> */}
                          
                      </div>
                      <div className="text-left  " style={{marginTop : "50px" , marginLeft : "15px"}}>
                            <div className="  font-weight-500"  style={{fontSize : "16px" , color : "black" , marginBottom: "0"}}>
                              {current_user ? current_user.username : ""}
                            </div>
                            <div className="h5 font-weight-300" style={{fontSize : "11px" , color : "black" , marginBottom: "0"}}>
                              {current_user ? current_user.fullname : ""}
                            </div>
                          </div>

                  </div>

                  <div className="ml-0 col-12 col-md-6 d-flex justify-content-end" style={{marginTop : "42px"}}>
                    <div className="w-100" style={{maxWidth : "350px"}}>
                      <div className="card-profile-stats d-flex justify-content-end row p-0 m-0">
                        <div className="mx-0 px-0 ">
                          <span className="heading">{current_user ? "20.8K" : 0}</span>
                          <span className="description">Punch</span>
                        </div>
                        <div className=" mx-4 px-0" style={{width : "3px"}}>
                          <img
                              alt="..."
                              className="separator_image"
                              src={
                                  separator
                              }
                          />
                        </div>
                        <div className="mx-0 px-0 ">
                          <span className="heading">{current_user ? "10.2K" : 0}</span>
                          <span className="description">Followers</span>
                        </div>
                        <div className=" mx-4 px-0"  style={{width : "3px"}}>
                          <img
                              alt="..."
                              className="separator_image"
                              src={
                                  separator
                              }
                          />
                        </div>
                        <div className="mx-0 px-0 ">
                          <span className="heading">{current_user ? "800" : 0}</span>
                          <span className="description">Following</span>
                        </div>
                        <div className=" ml-2 px-0 ">
                              <div
                                className="btn_more"
                                id="demo-customized-menu"
                                aria-controls={open ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                              >
                                <img
                                    alt="..."
                                    className="ml-3"
                                    style={{width : "5px" , height : "auto" , cursor : "pointer"}}
                                    src={
                                        details
                                    }
                                />
                              </div>
                              <StyledMenu
                                  id="demo-customized-menu"
                                  MenuListProps={{
                                    'aria-labelledby': 'demo-customized-button',
                                  }}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                >
                                  <MenuItem onClick={()=> {goToFavorites()}} disableRipple className="my-3" style={{width : "200px" , fontSize : "13px"}}>
                                    {/* <EditIcon /> */}
                                    My Favorites 
                                  </MenuItem>
                                  <MenuItem onClick={()=> {goToOffers()}} disableRipple className="my-3" style={{width : "200px" , fontSize : "13px"}}>
                                    {/* <FileCopyIcon /> */}
                                    My offers
                                  </MenuItem>
                                  {/* <Divider sx={{ my: 0.5 }} /> */}
                                  <MenuItem onClick={()=> {goToSettings()}} disableRipple className="my-3" style={{width : "200px" , fontSize : "13px"}}>
                                    {/* <ArchiveIcon /> */}
                                    Settings
                                  </MenuItem>
                                  <MenuItem onClick={handleClose} disableRipple className="my-3" style={{width : "200px" , fontSize : "13px"}}>
                                    {/* <MoreHorizIcon /> */}
                                    Privacy policy
                                  </MenuItem>
                                  <MenuItem onClick={handleClose} disableRipple className="my-3" style={{width : "200px" , fontSize : "13px"}}>
                                    {/* <MoreHorizIcon /> */}
                                    Terms of use
                                  </MenuItem>
                                </StyledMenu>
                        </div>

                      </div>
                    </div>
                  </div>
              </div>

              <div className="w-100 information_profile_div px-3">
                  <div className="d-flex justify-content-start align-items-center">
                    Author , Poet
                  </div>
                  <div className="d-flex justify-content-start align-items-center">
                    Mom and nature lover
                  </div>
                  <div className="d-flex justify-content-start align-items-center">
                    Links to my Esty poetry shop book below 
                  </div>
                  <div className="d-flex justify-content-start align-items-center link" style={{cursor : "pointer"}}>
                    linktr.ee/Christian_Hodland
                  </div>
                  <div className="d-flex justify-content-start align-items-center">
                    <img
                        alt="..."
                        className="mr-3"
                        style={{width : "20px" , height : "auto" , cursor : "pointer"}}
                        src={
                            address
                        }
                    />
                    0x7586..32145
                  </div>
                  <div className="d-flex justify-content-start align-items-center">
                    <img
                        alt="..."
                        className="mr-3"
                        style={{width : "16px" , height : "auto" , marginLeft : "2px" , cursor : "pointer"}}
                        src={
                            insta_icon
                        }
                    />
                    Christian_Hodland
                  </div>
              </div>
              

              
              <Row className="ml-0 w-100">
                  <Col className="order-xl-2 mb-5 mb-xl-0 mx-auto  p-0" xs="12" md="4">
                    <div className="mt-4 mx-auto all_post_space d-flex justify-content-start align-items-center" style={{maxWidth : "550px" , borderRadius : "10px"}}>
                      <div style={{minWidth : "100px"}}>
                          <div className={ (select === 1 ? "profile_post_option_active" : "")+" profile_post_option  "} onClick={ () => {setSelect(1)}}>
                            Posts 45
                            <div className="profile_post_option_active_line"></div>
                          </div>
                      </div>
                    </div>
              
                  </Col>
                  <Col className="order-xl-2 mb-5 mb-xl-0 mx-auto  p-0" xs="12" md="4">
                    <div className="mt-4 mx-auto all_post_space d-flex justify-content-center align-items-center" style={{maxWidth : "550px" , borderRadius : "10px"}}>
                      <div style={{minWidth : "100px"}}>
                      <div className={ (select === 2 ? "profile_post_option_active" : "")+" profile_post_option ml-4 "} onClick={ () => {setSelect(2)}}>
                          NFTs 5
                          <div className="profile_post_option_active_line"></div>
                        </div>
                      </div>
                    </div>
              
                  </Col>
                  <Col className="order-xl-2 mb-5 mb-xl-0 mx-auto  p-0" xs="12" md="4">
                    <div className="mt-4 mx-auto all_post_space d-flex justify-content-end align-items-center" style={{maxWidth : "550px" , borderRadius : "10px"}}>
                      <div style={{minWidth : "100px"}}>
                      <div className={ (select === 3 ? "profile_post_option_active" : "")+" profile_post_option ml-4 "}  onClick={ () => {setSelect(3)}}>
                          Hidden 0
                          <div className="profile_post_option_active_line"></div>
                        </div>
                      </div>
                    </div>
              
                  </Col>
                
          </Row>
            </CardBody>
          </Card>
        </Col>
        
        
          <Col xs="12" className="p-0 m-0 my-3">
            <div className="w-100 p-0 m-0 profile_posts_container">
              {select === 1 && postList.length > 0 &&
                renderPosts(postList)
              }
              {select === 2 && nftList.length > 0 &&
                renderPosts(nftList)
              }
              {select === 3 && hiddenList.length > 0 &&
                renderPosts(hiddenList)
              }
            </div>
            <div className="w-100 p-0 m-0">
              {select === 3 && hiddenList.length === 0 &&
                <div className="w-100 text_hidden">
                  <div className="w-100">Nothing Here</div>
                  <div className="w-100 text_hidden_small">You didn't hide any post/NFT yet</div>
                </div>
              }

            </div>
          </Col>
        </Row>
        

        <ToastContainer />

      </Container>
    );
  };
  
  export default AccountProfile;
  