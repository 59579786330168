import React from "react";

import { Col, Row, Container } from "reactstrap";

import "./Punchword.scss";

//icons
import phones from "../../../../assets/img/icons/PunchyToken/phones.svg";
import line from "../../../../assets/img/icons/PunchyToken/line.svg";
const Punchword = (props) => {
  return (
    <>
      <div
        className="w-100 p-0  d-flex justify-content-center align-items-center"
        style={{ marginTop: "100px", minHeight: "calc( 100vh - 100px )" }}
        id="Punchword"
      >
        <Container className="container_punchword">
          <Row className="row_punchword flex-sm-row-reverse ">
            <div className="background_row_punchword">
              <img src={line} alt="" />
            </div>

            <Col
              xs="12"
              md="6"
              className="p-0 m-0 d-flex justify-content-center align-items-center"
            >
              <img
                style={{ width: "100%", maxWidth: "710px" }}
                className="image_shown_phones"
                src={phones}
                alt=""
              />
            </Col>
            <Col
              xs="12"
              md="6"
              className="p-0 m-0 d-flex justify-content-center align-items-center"
            >
              <div className="w-75 my-2" style={{ maxWidth: "500px" }}>
                <div className="punchytoken_text1">
                  Punchword is a Web 3 social media giving the power back to
                  creators.
                </div>
                <div className="punchytoken_text2">
                  Based on revenue sharing our platform allow creators to own
                  their content (via NFTs) and advertiser to launch viral
                  campaigns. <br className="d-sm-none d-lg-inline" /> Freedom of
                  speech is at the center or our values.
                </div>
                <br></br>
                <div className="punchytoken_text2">
                  In collaboration with the Sustainable Economy Forum, Punchword
                  will soon announce a new feature focused on sustainability
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Punchword;
