
import { auth } from "app/firebase";
import { db } from "app/firebase";
import { providerOut } from "app/firebase";
import { addDoc, collection } from "firebase/firestore";
import { Link, useHistory } from "react-router-dom";
// reactstrap components
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";

import punch_logo from '../../assets/img/icons/logo.svg' ;

import './UserSidebar.scss' ;

//icons 
import home_icon from '../../assets/img/icons/icons/home.svg' ;
import home_icon_hover from '../../assets/img/icons/icons_hover/home.svg' ;


import market_icon from '../../assets/img/icons/icons/market.svg' ;
import market_icon_hover from '../../assets/img/icons/icons_hover/market.svg' ;


import messages_icon from '../../assets/img/icons/icons/messages.svg' ;
import messages_icon_hover from '../../assets/img/icons/icons_hover/messages.svg' ;


import notifications_icon from '../../assets/img/icons/icons/notifications.svg' ;
import notifications_icon_hover from '../../assets/img/icons/icons_hover/notifications.svg' ;


import profile_icon from '../../assets/img/icons/icons/profile.svg' ;
import profile_icon_hover from '../../assets/img/icons/icons_hover/profile.svg' ;
import logout_icon from '../../assets/img/icons/icons/logout.svg' ;
import logout_icon_hover from '../../assets/img/icons/icons_hover/logout.svg' ;
import search_icon from '../../assets/img/icons/icons/search.svg' ;
import $ from 'jquery';

import PerfectScrollbar from 'react-perfect-scrollbar' ;


const UserSidebar = (session) => {
  
  console.log(session.session);

  
  var current_user = localStorage.getItem("user") ;

  if(current_user && current_user !== "") {
    current_user = JSON.parse(current_user) ;
  }else {
    current_user = null ;
  }


  const  history = useHistory();
  

  // const userName = useSelector(selectUserName) ;
  // const userEmail = useSelector(selectUserEmail) ;

  const signOut = () => {
      providerOut(auth).then(res => {
      console.log("results");
      var current_user = localStorage.getItem("user") ;

        if(current_user && current_user !== "") {
          localStorage.removeItem("user") ;
        }
      console.log(res);
      history.push("/auth/login");
    }).catch( e => {
      alert(e.message) ;
    })
  }


  const openNotificationsBar = () => {
    $(".all_notifications_bar").css({"display" : "flex"})   ;  
    }


  


  return (
    <>
      <Card className="shadow border-0 mt-4 mx-auto all_sidenav" style={{maxWidth : "600px" , background : "#fffffd" ,borderRadius : "10px"}}>
        
            <CardBody >

            <PerfectScrollbar>

            <div className="d-flex justify-content-start align-items-center">
                <Row className="p-3">
                    <Col xs="2"  className="d-flex justify-content-start align-items-center">
                        <div className="avatar" style={{width : "40px" , height : "40px"}}>
                            <img
                                alt="..."
                                style={{width : "40px" , height : "auto"}}
                                src={
                                    punch_logo
                                }
                            />

                        </div>
                    </Col>
                    <Col xs="10" className="d-flex justify-content-start align-items-center">
                        <strong className="ml-2 text-dark " style={{fontSize : "20px" , fontWeight : 600}}>
                            Punchword
                        </strong>
                    </Col>
                </Row>
  
            </div>


            <Form role="form">
              <FormGroup className="my-3"  to="/user/search" tag={Link}>
                <InputGroup className="input-group-alternative" 
                            style={{  background: "#eef1ee" , borderRadius: "20px" ,  overflow: "hidden" , 
                            boxShadow: "none" , border: "none", transition: "none", }}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{background : "#eef1ee"}}>
                        <div  style={{width : "25px" , height : "25px"}}>
                            <img
                                alt="..."
                                style={{width : "25px" , height : "auto"}}
                                src={
                                    search_icon
                                }
                            />

                        </div>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Search..."
                    type="text"
                    disabled
                    style={{background : "#eef1ee" ,  color : "#a1a1a0"}}
                    // autoComplete="new-email"
                    // onChange={e => setEmail(e.currentTarget.value)}
                  />
                </InputGroup>
              </FormGroup>
              </Form>


            <Row style={{fontSize : "12px"}} className="row w-100 m-0">
                    <Col xs="12 d-flex justify-content-start align-items-center actionPost mt-2">
                        <Row className="py-3 w-100" to="/user/home" tag={Link}>
                            <Col xs="2"  className="d-flex justify-content-start align-items-center">
                                <div  style={{width : "30px" , height : "30px"}}>
                                    <img
                                        alt="..."
                                        className="item_image"
                                        style={{width : "30px" , height : "auto"}}
                                        src={
                                            session.session === "/user/home" ? home_icon_hover : home_icon
                                        }
                                    />
                                    <img
                                        alt="..."
                                        className="item_image_hover"
                                        style={{width : "30px" , height : "auto"}}
                                        src={
                                            home_icon_hover
                                        }
                                    />

                                </div>
                            </Col>
                            <Col xs="8" className="d-flex justify-content-start align-items-center text_action">
                                <strong className="ml-2 " style={{ fontSize : "14px" , color : session.session === "/user/home" ? "rgb(227 6 19)" :  "#a1a1a0" }}>
                                    Home
                                </strong>
                            </Col>
                            <Col xs="2"  className="d-flex justify-content-start align-items-center">
                                    {/* <i className="ni ni-like-2 mr-2"></i> */}
                            </Col>

                        </Row>
                        
                    </Col>

                    <Col xs="12 d-flex justify-content-start align-items-center actionPost mt-2">
                        <Row className="py-3 w-100"  to="/user/market" tag={Link}>
                            <Col xs="2"  className="d-flex justify-content-start align-items-center">
                                <div  style={{width : "30px" , height : "30px"}}>
                                    <img
                                        alt="..."
                                        className="item_image"
                                        style={{width : "30px" , height : "auto"}}
                                        src={
                                            
                                            session.session === "/user/market" ? market_icon_hover : market_icon
                                        }
                                    />
                                    <img
                                        alt="..."
                                        className="item_image_hover"
                                        style={{width : "30px" , height : "auto"}}
                                        src={
                                          market_icon_hover
                                        }
                                    />

                                </div>
                            </Col>
                            <Col xs="8" className="d-flex justify-content-start align-items-center text_action">
                                <strong className="ml-2 " style={{ fontSize : "14px" , color : session.session === "/user/market" ? "rgb(227 6 19)" :  "#a1a1a0"}}>
                                     NFT market
                                </strong>
                            </Col>
                            <Col xs="2"  className="d-flex justify-content-start align-items-center">
                                    {/* <i className="ni ni-like-2 mr-2"></i> */}
                            </Col>

                        </Row>
                        
                    </Col>



                    <Col xs="12 d-flex justify-content-start align-items-center actionPost mt-2">
                        <Row className="py-3 w-100" to="/user/messages" tag={Link}>
                            <Col xs="2"  className="d-flex justify-content-start align-items-center">
                                <div  style={{width : "30px" , height : "30px"}}>
                                    <img
                                        alt="..."
                                        className="item_image"
                                        style={{width : "30px" , height : "auto"}}
                                        src={
                                            
                                            session.session === "/user/messages" ? messages_icon_hover : messages_icon
                                        }
                                    />
                                    <img
                                        alt="..."
                                        className="item_image_hover"
                                        style={{width : "30px" , height : "auto"}}
                                        src={
                                          messages_icon_hover
                                        }
                                    />

                                </div>
                            </Col>
                            <Col xs="8" className="d-flex justify-content-start align-items-center text_action">
                                <strong className="ml-2 " style={{ fontSize : "14px" , color : session.session === "/user/messages" ? "rgb(227 6 19)" :  "#a1a1a0"}}>
                                    Messages
                                </strong>
                            </Col>
                            <Col xs="2"  className="d-flex justify-content-start align-items-center">
                                    {/* <i className="ni ni-like-2 mr-2"></i> */}
                            </Col>

                        </Row>
                        
                    </Col>





                    <Col xs="12 d-flex justify-content-start align-items-center actionPost mt-2">
                        <Row className="py-3 w-100" onClick={openNotificationsBar}>
                            <Col xs="2"  className="d-flex justify-content-start align-items-center">
                              <div  style={{width : "30px" , height : "30px"}}>
                                    <img
                                        alt="..."
                                        className="item_image"
                                        style={{width : "30px" , height : "auto"}}
                                        src={
                                            notifications_icon
                                        }
                                    />
                                    <img
                                        alt="..."
                                        className="item_image_hover"
                                        style={{width : "30px" , height : "auto"}}
                                        src={
                                          notifications_icon_hover
                                        }
                                    />
                              
                             </div>
                            </Col>
                            <Col xs="8" className="d-flex justify-content-start align-items-center text_action">
                                <strong className="ml-2 " style={{ fontSize : "14px" , color : "#a1a1a0"}}>
                                Notifications
                                </strong>
                            </Col>
                            <Col xs="2"  className="d-flex justify-content-start align-items-center">
                                    {/* <i className="ni ni-like-2 mr-2"></i> */}
                            </Col>

                        </Row>
                        
                    </Col>


                    <Col xs="12 d-flex justify-content-start align-items-center actionPost mt-2">
                        <Row className="py-3 w-100"  to="/user/profile" tag={Link}>
                            <Col xs="2"  className="d-flex justify-content-start align-items-center">
                              
                                <div  style={{width : "30px" , height : "30px"}}>
                                    {session.session === "/user/profile" && 
                                        <img
                                            alt="..."
                                            className="item_image"
                                            style={{marginLeft : "4px" , marginTop : "4px"  ,width : "21px" , height : "auto"}}
                                            src={
                                            profile_icon_hover
                                            }
                                        />
                                    
                                    }
                                    {session.session !== "/user/profile" && 
                                        <img
                                            alt="..."
                                            className="item_image"
                                            style={{width : "30px" , height : "auto"}}
                                            src={
                                                 profile_icon
                                            }
                                        />
                                    
                                    }
                                    <img
                                        alt="..."
                                        className="item_image_hover"
                                        style={{marginLeft : "4px" , marginTop : "4px"  ,width : "21px" , height : "auto"}}
                                        src={
                                          profile_icon_hover
                                        }
                                    />
                                </div>
                            </Col>
                            <Col xs="8" className="d-flex justify-content-start align-items-center text_action">
                                <strong className="ml-2 " style={{ fontSize : "14px" , color : session.session === "/user/profile" ? "rgb(227 6 19)" :  "#a1a1a0"}}>
                                Profile
                                </strong>
                            </Col>
                            <Col xs="2"  className="d-flex justify-content-start align-items-center">
                                    {/* <i className="ni ni-like-2 mr-2"></i> */}
                            </Col>

                        </Row>
                        
                    </Col>  
                </Row>
            </PerfectScrollbar>
            
            
            </CardBody>
            
            <CardFooter className="py-0" style={{ borderRadius : "10px"}}>
                <Row style={{fontSize : "12px"}} className="row w-100 m-0">
                    <Col xs="12 d-flex justify-content-start align-items-center actionPost mt-2">
                            <Row className="py-3 w-100"  onClick={signOut}>
                                <Col xs="2"  className="d-flex justify-content-start align-items-center">
                                <div  style={{width : "30px" , height : "30px"}}>
                                    <img
                                        alt="..."
                                        className="item_image"
                                        style={{width : "30px" , height : "auto"}}
                                        src={
                                            logout_icon
                                        }
                                    />
                                    <img
                                        alt="..."
                                        className="item_image_hover"
                                        style={{width : "30px" , height : "auto"}}
                                        src={
                                            logout_icon_hover
                                        }
                                    />

                                </div>
                                </Col>
                                <Col xs="8" className="d-flex justify-content-start align-items-center text_action">
                                    <strong className="ml-2 " style={{ fontSize : "14px" , color : "#a1a1a0"}}>
                                    Logout
                                    </strong>
                                </Col>
                                <Col xs="2"  className="d-flex justify-content-start align-items-center">
                                        {/* <i className="ni ni-like-2 mr-2"></i> */}
                                </Col>

                            </Row>
                            
                        </Col>  
                        
                </Row>
            </CardFooter>
          </Card>
      
    </>
  );
};

export default UserSidebar;
