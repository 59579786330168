import React from "react";

import {
    Card,
    CardBody,
    Badge ,
    Col,
    CardFooter,
    Row,
  } from "reactstrap";
  

import Link from '@mui/material/Link';

import ReactPlayer from 'react-player'
import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'

//icons 
import like from '../../../../../assets/img/icons/post/like.svg' ;


import original from '../../../../../assets/img/icons/market/original.svg' 
import timing from '../../../../../assets/img/icons/market/timing.svg' 


import './MartketItemSmaller.scss' ;

TimeAgo.addDefaultLocale(en)

const MartketItemSmaller = (props) => {


    // {
    //     uid: null ,
    //     pid: null ,
    //     createdAt: 0 ,
    //     uImgURL:  "https://firebasestorage.googleapis.com/v0/b/react-auth-be92d.appspot.com/o/images%2FgZrVu1cKPlRWc1u6VHp9u6tJWZ23_abd.jpg?alt=media&token=fa410ce2-ac8c-405c-9d73-62da2602f414"  ,
    //     audioURL:  
    //     "" ,
    //     //  "https://interactive-examples.mdn.mozilla.net/media/cc0-audio/t-rex-roar.mp3" ,
    //     likeCount:  0 ,
    //     commentCount:  0 ,
    //     shareCount:  0 ,
        
    //     fullName: "Abdennour Badeche" ,
    //     pImgURL: 
    //     "" , 
    //     //  "https://firebasestorage.googleapis.com/v0/b/react-auth-be92d.appspot.com/o/images%2F296178014_153823693897401_5120699166089147296_n.jpg?alt=media&token=b6a4fbe1-7b28-41d3-a7b3-e8d3fd9e5b5f" ,
    //     smallPImgURL:   null ,
    //     mediumPImgURL: null ,
    //     largePImgURL:  null ,

    //     publicProfile: true ,

    //     videoUrl : "https://youtu.be/jByb9gvUaW8" ,
    //     thumbnailUrl : "https://i.ytimg.com/vi/jByb9gvUaW8/hqdefault.jpg?sqp=-oaymwEXCOADEI4CSFryq4qpAwkIARUAAIhCGAE=&rs=AOn4CLAfVBm2-z3U1PlHYjIfypthspdZqA" ,
    //     mention : [{ uid : "" , username : "Abdennour" } , { uid : "" , username : "Badeche" } , { uid : "" , username : "Abdou" }] ,
    //     description : "Retrouve MRC sur : Facebook : https://www.facebook.com/momomrcofficiel " ,
    //     tags : ["first" , "second" , "third"]
    // }

    
    // const dispatch = useDispatch() ; 
    
  
    // const userName = useSelector(selectUserName) ;
    // const userEmail = useSelector(selectUserEmail) ;

  
  
    return (
          <Card className="bg-white shadow border-0 mt-4 all_market_item_space mr-5" style={{width : "200px" , borderRadius : "10px"}}>
            
            <CardBody className="p-0">
                {/* {props.post && props.post.mention && props.post.mention.length > 0 && 
                
                    <div className="w-100 d-flex justify-content-start align-items-center mt-2">
                        <i class="fa fa-user mr-3" aria-hidden="true" style={{fontSize: "10px"}}></i>
                        <div > 
                            {renderMention(props.post.mention)}

                        </div>
                    </div>
                    
                } */}
                {props.post && props.post.pImgURL && props.post.pImgURL !== '' &&
                <div className="post_image_container" style={{borderRadius : "10px" , overflow : "hidden" , height : "200px" , background : "url("+props.post.pImgURL+")" }}>
                        {/* <img
                            alt="..."
                            style={{width : "100%" , height : "auto"}}
                            src={
                                props.post.pImgURL 
                            }
                        /> */}

                </div>
              }
              

              {props.post && props.post.videoUrl && props.post.videoUrl !== '' &&
                <div className="video_container" style={{borderRadius : "10px" , overflow : "hidden" }}>
                  
                      <ReactPlayer
                      url={
                          props.post.videoUrl 
                      }
                      light={
                        props.post.thumbnailUrl 
                      }
                      controls
                      width={"100%"}
                      height={"200px"}
                      />

                      <div className="video_timing_space d-flex justify-content-center align-items-center font-weight-600" style={{fontSize :"12px"}}>
                                <div   className="ml-1  h-100  d-flex justify-content-center align-items-center">
                                    <img
                                        alt="..."
                                        style={{width : "9px" , height : "auto" , marginTop : "-3px"}}
                                        src={
                                            timing
                                        }
                                    />

                                </div> 
                                <span className="ml-3 h-100 d-flex justify-content-center align-items-center ">
                                    00:00:13:14
                                </span>
                      </div>

              </div>
            }

            {/* {props.post && props.post.description && props.post.description !== '' &&
                <div  style={{padding : ".2rem 1.5rem"}} >
                    <p className="h5 font-weight-500" style={{textAlign : "left" , fontSize : "11px" , color : "#2f3232"}}>{props.post.description}</p>

                </div>
            } */}

            {/* {props.post && props.post.tags && props.post.tags.length > 0 && 
        
                <div className="w-100 d-flex justify-content-start align-items-center mt-2">
                    <i class="fa fa-tags mr-3" aria-hidden="true" style={{fontSize: "10px"}}></i>
                    <div > 
                        {renderTags(props.post.tags)}

                    </div>
                </div>
                
            } */}
            </CardBody>
            <CardFooter style={{padding  : "7px" , borderRadius : "10px" , border : "none"}} className="py-3">
                <div className="w-100 d-flex align-items-start justify-content-between py-0">
                    <Row style={{fontSize : "12px" , width : "130px" , color :"black"}} className="row m-0" >
                        <Col xs="12 d-flex justify-content-start align-items-center ">
                            <div className="d-flex justify-content-start align-items-center ">
                                Before me
                            </div>
                        </Col>
                        <Col xs="12 d-flex justify-content-start align-items-start ">
                            <div className="d-flex justify-content-start align-items-center" style={{fontSize : "9px" ,color : "#808080"}} >
                                JohnFKennedyOnceSaid

                            </div>

                            <div  style={{width : "10px" , height : "10px"}} className="ml-1 d-flex justify-content-start align-items-center">
                                    <img
                                        alt="..."
                                        style={{width : "10px" , height : "auto"}}
                                        src={
                                            original
                                        }
                                    />

                                </div> 
                        </Col>
                    </Row>
                    <div style={{width : "max-centent" , color : "#e30613" , fontSize : "12px"}} className="px-3 py-0  font-weight-600">
                            
                        <div className=" d-flex justify-content-center align-items-center">
                                <div  style={{width : "20px" , height : "20px"}}>
                                    <img
                                        alt="..."
                                        style={{width : "20px" , height : "auto"}}
                                        src={
                                            like
                                        }
                                    />

                                </div> 
                                {/* {props.post  && props.post.likeCount > 0 && 
                                    <div className="d-flex justify-content-end align-items-center ml-2"  style={{fontSize : "10px" , color : "black"}}>
                                        {props.post.likeCount}  
                                    </div>
                                }
                                {props.post  && props.post.likeCount === 0 && 
                                    <div className="d-flex justify-content-end align-items-center ml-2"  style={{fontSize : "10px" , color : "black"}}>
                                        17.8K
                                    </div>
                                } */}
                        </div>
                    </div>
                </div>

                <div className="w-100 d-flex align-items-center justify-content-between py-0 mt-2">
                    <Row style={{fontSize : "12px" , width : "100px"}} className="row m-0" >
                        <Col xs="12 d-flex justify-content-start align-items-center ">
                            <div className="d-flex justify-content-start align-items-center">
                                <div className="price  font-weight-600" style={{fontSize : "12px" , color : "black"}}>
                                    1.45
                                </div>
                                <div className="currency ml-1"  style={{fontSize : "9px" , color : "#808080"}}>
                                    ETH
                                </div>
                            </div>
                        </Col>
                        
                    </Row>
                    <div style={{width : "max-centent" , color : "white" , background : "#e30613" , borderRadius : "15px" , height : "30px" , fontSize : "9px" , cursor : "pointer"}} 
                        className="px-3  font-weight-600 d-flex justify-content-center align-items-center">
                            PLACE A BID
                    </div>
                </div>
            </CardFooter>
          </Card>
    );
};
  
export default MartketItemSmaller;