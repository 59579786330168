import React from "react";

import {
    Col,
    Row,
    Container,
  } from "reactstrap";
  
import './Bitmap.scss' ;



//icons 
import phones from '../../../../assets/img/icons/LandDownload/phones.svg' ;
import line from '../../../../assets/img/icons/LandDownload/line.svg' ;
import playstore from '../../../../assets/img/icons/LandDownload/playstore.svg' ;
import appstore from '../../../../assets/img/icons/LandDownload/appstore.svg' ;
import myPdfFile from '../../../../assets/whitepaper.pdf';


  const Bitmap = (props) => {

    const download_bitmart = () => {
        window.open('https://www.bitmart.com/trade/en-US?symbol=PUNCH_USDT', '_blank').focus();
    }
    return (
          <>
          <div className="w-100 p-0 m-0 d-flex justify-content-center align-items-center" style={{minHeight : "calc( 100vh - 100px )"}} id="Whitepaper">
        
            <Container className="container_land_download">
                <Row className="row_land_download ">
                    <div className="background_row_land_download">
                        <img src={
                            line
                        }
                        alt=''/>
                    </div>
                    <Col xs="12" className="p-0 m-0 d-flex justify-content-center align-items-center">
                        <div className="w-75">
                            <div className="punchytoken_text2 text-center">
                                Punch
                            </div>
                            <div className="punchytoken_text1 text-center">
                                the Punchword’s token available on « Bitmart »
                            </div>
                            <div  className="w-100 d-flex align-items-center justify-content-center" style={{ }}>
                                    <div  className="font-weight-600 land_contact_btn d-flex justify-content-center align-items-center" onClick={download_bitmart}>
                                        Buy on Bitmart
                                    </div>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
            </div>
          </>
    );
  };
  
  export default Bitmap;
  