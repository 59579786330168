import React from "react";

import {
    
    Card,
    CardHeader,
    CardBody,
    Badge ,
    Col,
    CardFooter,
    Row,
  } from "reactstrap";
  
import './Post.scss' ;
import ReactAudioPlayer from 'react-audio-player';

import punch_logo from '../../../../../assets/img/icons/logo.svg' ;

import Link from '@mui/material/Link';

import ReactPlayer from 'react-player'
import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago'

import en from 'javascript-time-ago/locale/en.json'

//icons 
import like from '../../../../../assets/img/icons/post/like.svg' ;
import comment from '../../../../../assets/img/icons/post/comment.svg' ;
import share from '../../../../../assets/img/icons/post/share.svg' ;
import details from '../../../../../assets/img/icons/post/details.svg' ;


TimeAgo.addDefaultLocale(en)

  const Post = (props) => {


    // {
    //     uid: null ,
    //     pid: null ,
    //     createdAt: 0 ,
    //     uImgURL:  "https://firebasestorage.googleapis.com/v0/b/react-auth-be92d.appspot.com/o/images%2FgZrVu1cKPlRWc1u6VHp9u6tJWZ23_abd.jpg?alt=media&token=fa410ce2-ac8c-405c-9d73-62da2602f414"  ,
    //     audioURL:  
    //     "" ,
    //     //  "https://interactive-examples.mdn.mozilla.net/media/cc0-audio/t-rex-roar.mp3" ,
    //     likeCount:  0 ,
    //     commentCount:  0 ,
    //     shareCount:  0 ,
        
    //     fullName: "Abdennour Badeche" ,
    //     pImgURL: 
    //     "" , 
    //     //  "https://firebasestorage.googleapis.com/v0/b/react-auth-be92d.appspot.com/o/images%2F296178014_153823693897401_5120699166089147296_n.jpg?alt=media&token=b6a4fbe1-7b28-41d3-a7b3-e8d3fd9e5b5f" ,
    //     smallPImgURL:   null ,
    //     mediumPImgURL: null ,
    //     largePImgURL:  null ,

    //     publicProfile: true ,

    //     videoUrl : "https://youtu.be/jByb9gvUaW8" ,
    //     thumbnailUrl : "https://i.ytimg.com/vi/jByb9gvUaW8/hqdefault.jpg?sqp=-oaymwEXCOADEI4CSFryq4qpAwkIARUAAIhCGAE=&rs=AOn4CLAfVBm2-z3U1PlHYjIfypthspdZqA" ,
    //     mention : [{ uid : "" , username : "Abdennour" } , { uid : "" , username : "Badeche" } , { uid : "" , username : "Abdou" }] ,
    //     description : "Retrouve MRC sur : Facebook : https://www.facebook.com/momomrcofficiel " ,
    //     tags : ["first" , "second" , "third"]
    // }

    
    // const dispatch = useDispatch() ; 
    
  
    // const userName = useSelector(selectUserName) ;
    // const userEmail = useSelector(selectUserEmail) ;

  

    const renderTags = (tags) => {
        var list = [] ; 

        tags.forEach(tag => {
            list.push(<Badge color="dark mr-2" className="tagPost" style={{backgroundColor: "rgb(248 211 183)" , fontSize: "8px"}}>#{tag}</Badge>) ;
        });

        return list ;
    }


    const renderMention = (mentions) => {
        var list = [] ; 

        mentions.forEach(mention => {
            list.push(<Link  href={mention.uid} variant="body2" className="mr-2 mentionPost" style={{ fontSize: "10px"}}>{mention.username}</Link>) ;
        });

        return list ;
    }
    
  
  
    return (
          <Card className="bg-secondary shadow border-0 mt-4 mx-auto all_post_space" style={{maxWidth : "550px" , borderRadius : "10px"}}>
            <CardHeader className="bg-transparent" style={{border : "none"}}>

            <div className="w-100 d-flex align-items-center justify-content-between py-0">
                <div className="d-flex justify-content-start align-items-center">
                    <div className="avatar" style={{width : "40px" , height : "40px"}}>
                        <img
                            alt="..."
                            style={{width : "40px" , height : "auto"}}
                            src={
                                props.post && props.post.uImgURL && props.post.uImgURL !== '' ? props.post.uImgURL : punch_logo
                            }
                        />

                    </div>
                    <div >
                        <strong className=" ml-2 d-flex justify-content-center align-items-center" style={{fontSize : "12px" , color : "#2f3232"}}>
                            {props.post && props.post.fullName && props.post.fullName !== '' ? props.post.fullName : ""}
                        </strong>

                        {props.post && props.post.createdAt !== null && 
                                
                            <div className="ml-2  d-flex justify-content-start align-items-center m-0 p-0" style={{fontSize : "9px" , color : "rgb(142 142 142)"}}>
                                <ReactTimeAgo date={props.post.createdAt.toDate()} locale="dz"/>
                                    {/* <i class="fa fa-calendar mr-3" aria-hidden="true" style={{fontSize: "10px"}}></i> */}
                                    {/* <span style={{margin: "0" , fontSize: "11px"}}>{props.post.createdAt.toDate().toDateString()}</span>
                                    <span style={{margin: "0" , marginLeft : "7px" , fontSize: "11px"}}>{props.post.createdAt.toDate().toLocaleTimeString('en-US')}</span> */}
                            </div>
                            
                        }



                    </div>
            </div>
            <div style={{width : "max-centent" , color : "#335fe2" , fontSize : "12px"}} className="px-3 py-2  font-weight-600">
                    <span style={{cursor : "pointer"}}>Follow</span>

                        <img
                            alt="..."
                            className="ml-3"
                            style={{width : "4px" , height : "auto" , cursor : "pointer"}}
                            src={
                                details
                            }
                        />
            </div>
            
              {/* <div className="h5 font-weight-300 ml-2">
                {props.post && props.post.fullName && props.post.fullName !== '' ? props.post.fullName : ""}
              </div> */}
  
            </div>
            
                
            </CardHeader>
            <CardBody className="p-0">

                {/* {props.post && props.post.description && props.post.description !== '' &&
                    <div  style={{padding : ".2rem 1.5rem"}} >
                        <p className="h5 font-weight-500" style={{textAlign : "left" , fontSize : "11px" , color : "#2f3232"}}>{props.post.description}</p>

                    </div>
                } */}
                {/* {props.post && props.post.mention && props.post.mention.length > 0 && 
                
                    <div className="w-100 d-flex justify-content-start align-items-center mt-2">
                        <i class="fa fa-user mr-3" aria-hidden="true" style={{fontSize: "10px"}}></i>
                        <div > 
                            {renderMention(props.post.mention)}

                        </div>
                    </div>
                    
                } */}
                {props.post && props.post.pImgURL && props.post.pImgURL !== '' &&
                <div >
                        <img
                            alt="..."
                            style={{width : "100%" , height : "auto"}}
                            src={
                                props.post.pImgURL 
                            }
                        />

                </div>
              }

                {props.post && props.post.audioURL && props.post.audioURL !== '' &&
                <div >
                    
                        <ReactAudioPlayer
                        src={
                            props.post.audioURL 
                        }
                        style={{width : "100%" }}
                        className="mt-2"
                        controls
                        />

                </div>
              }

              {props.post && props.post.videoUrl && props.post.videoUrl !== '' &&
              <div >
                  
                      <ReactPlayer
                      url={
                          props.post.videoUrl 
                      }
                      light={
                        props.post.thumbnailUrl 
                      }
                      className="mt-2"
                      controls
                      width={"100%"}
                      />

              </div>
            }

            {/* {props.post && props.post.tags && props.post.tags.length > 0 && 
        
                <div className="w-100 d-flex justify-content-start align-items-center mt-2">
                    <i class="fa fa-tags mr-3" aria-hidden="true" style={{fontSize: "10px"}}></i>
                    <div > 
                        {renderTags(props.post.tags)}

                    </div>
                </div>
                
            } */}
            </CardBody>
            <CardFooter style={{padding  : "7px" , borderRadius : "10px"}} className="py-3">
                <div className="w-100 d-flex align-items-center justify-content-between py-0">
                    <Row style={{fontSize : "12px" , width : "250px"}} className="row m-0" >
                        <Col xs="4 d-flex justify-content-center align-items-center ">
                            <div className=" text-muted d-flex justify-content-center align-items-center">
                                    <div  style={{width : "25px" , height : "25px"}}>
                                        <img
                                            alt="..."
                                            style={{width : "25px" , height : "auto" , cursor : "pointer"}}
                                            src={
                                                like
                                            }
                                        />

                                    </div> 
                                    {props.post  && props.post.likeCount > 0 && 
                                        <div className="d-flex justify-content-end align-items-center ml-2"  style={{fontSize : "12px" , color : "#2f3232"}}>
                                            {props.post.likeCount}  
                                        </div>
                                    }
                            </div>
                        </Col>
                        <Col xs="4 d-flex justify-content-center align-items-center ">
                            <div className=" text-muted d-flex justify-content-center align-items-center">
                                    <div  style={{width : "25px" , height : "25px"}}>
                                        <img
                                            alt="..."
                                            style={{width : "25px" , height : "auto" , cursor : "pointer"}}
                                            src={
                                                comment
                                            }
                                        />

                                    </div> 
                                    {props.post  && props.post.commentCount > 0 && 
                                        <div className="d-flex justify-content-end align-items-center ml-2"  style={{fontSize : "12px" , color : "#2f3232"}}>
                                            {props.post.commentCount}  
                                        </div>
                                    }
                            </div>
                        </Col>
                        <Col xs="4 d-flex justify-content-center align-items-center ">
                            <div className=" text-muted d-flex justify-content-center align-items-center">
                                    <div  style={{width : "25px" , height : "25px"}}>
                                        <img
                                            alt="..."
                                            style={{width : "25px" , height : "auto" , cursor : "pointer"}}
                                            src={
                                                share
                                            }
                                        />

                                    </div> 
                                    {props.post  && props.post.shareCount > 0 &&
                                        <div className="d-flex justify-content-end align-items-center ml-2"  style={{fontSize : "12px" , color : "#2f3232"}}>
                                            {props.post.shareCount}  
                                        </div>
                                    }
                            </div>
                        </Col>
                    </Row>
                    <div style={{width : "max-centent" , color : "#e30613" , fontSize : "12px" , cursor : "pointer"}} className="px-3 py-2  font-weight-600">
                            MAKE OFFER
                    </div>
                </div>
            </CardFooter>
          </Card>
    );
  };
  
  export default Post;
  