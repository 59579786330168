
import React, { useState } from "react";
import { useLocation, Route, Switch, Redirect, useHistory } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import UserNavbar from "components/Navbars/UserNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "routes.js";
import { auth } from "app/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "app/firebase";

import UserSidebar from "components/Sidebar/UserSidebar";
import NotificationsBar from "components/Sidebar/NotificationsBar";
import StoriesBar from "views/pages/Account/Home/Stories/StoriesBar";

const User = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  
  const [user , setUser] = useState(null) ;

  var user_email = "" ;

  const  history = useHistory();

  React.useEffect(() => {
    // auth.onAuthStateChanged(async(user) => {
    //    if (!user) {
    //     history.push('/auth/login')
    //   }else {
    //     user_email = user.email ;
    //     var current_user = localStorage.getItem("user") ;

    //     if(current_user && current_user !== "") {
    //       current_user = JSON.parse(current_user) ;
    //       console.log("current_user");
    //       console.log(current_user);
    //     }else {
    //       const q = query(collection(db, "Users"), where("uid", "==", user.uid));
    //       const querySnapshot = await getDocs(q);
    //       querySnapshot.forEach((doc) => {
    //         console.log(doc.data()) ;
    //         if(doc.data().uid) {
    //           localStorage.setItem("user" , JSON.stringify(doc.data())) ;
    //           setUser(doc.data()) ;
    //         }
    //       });
    //     }

    //     console.log(user.email);
    //   };
    // })
 },[])

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/user") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  // linear-gradient(#ed7055 , #fff451  )"
  return (
    <>
      <div className="main-content"   ref={mainContent}>
      {/* <UserNavbar session={props.location.pathname} /> */}
        <div className="header py-0 py-lg-0 d-flex justify-content-center align-items-start" style={{ background:"#eef1ee" , minHeight : "100vh"}} >
          <Container style={{maxWidth : "1410px" , minHeight : "700px"}}>
          <UserSidebar session={props.location.pathname}></UserSidebar> 


            <div className="header-body text-center mb-7" style={{paddingLeft : "350px"}}>
            <Row className="main_user_all_row">
            <Col xs="12">
              <Row className=" w-100 ml-0">
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="*" to="/user/home" />
                </Switch>
                  <NotificationsBar></NotificationsBar>
              </Row>
            </Col>
            </Row>

            </div>
          </Container>
        </div>
        {/* Page content */}
       
      </div>

      <StoriesBar></StoriesBar>
    </>
  );
};

export default User;
