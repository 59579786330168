import React from "react";

import {
    Col,
    Row,
    Container,
  } from "reactstrap";
  
import './LandComingSoon.scss' ;



//icons 
import phones from '../../../../assets/img/icons/LandComingSoon/phones.svg' ;
import line from '../../../../assets/img/icons/LandComingSoon/line.svg' ;

  const LandComingSoon = (props) => {

    return (
          <>
          <div className="w-100 p-0 m-0 d-flex justify-content-center align-items-center" id="NewVersion">
            <Container className="container_land_coming_soon">
                <Row className="row_land_coming_soon my-3">
                    <Col xs="12" className="p-0 m-0  mb-5 d-flex justify-content-center align-items-center">
                            <div className="punchytoken_text1  text-center" style={{fontSize : "50px"}}>
                                Coming soon
                            </div>
                    </Col>
                    <Col xs="12" className="p-0 m-0 d-flex justify-content-center align-items-center">
                            <div className="punchytoken_text2 text-center" style={{maxWidth : "1000px"}}>
                                A new version of Punchword is coming soon, it will allow advertisers to launch viral campaigns led by big creators to sponsor many smaller creators, and posts can be turned into NFTs allowing them to fully monetize their content. 
                            </div>
                    </Col>

                    <Col xs="12" className="p-0 m-0  mt-5 d-flex justify-content-center align-items-center" style={{zIndex : "3" }}>
                            <div className="punchytoken_text1  text-center"  style={{maxWidth : "1000px" , background : "white"}}>
                                Screenshots from the future version's prototype
                            </div>
                    </Col>


                    <Col xs="12" className="p-0 m-0 d-flex justify-content-center align-items-center" style={{zIndex : "2" , position : "relative" , marginTop : "50px"}}>
                        <img className="phones_image_land_coming_soon"
                        src={
                            line
                        }
                        alt=''
                        />
                        <img  style={{ width : "90%" , marginTop : "150px"}} 
                        src={
                            phones
                        }
                        alt=''
                        />
                    </Col>
                </Row>
            </Container>
            </div>
          </>
    );
  };
  
  export default LandComingSoon;
  